import { Component, Inject, Input, Optional, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { ElementBase } from '../../form';


@Component({
  selector: 'app-form-date-range',
  templateUrl: './form-date-range.component.html',
  styleUrls: ['./form-date-range.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormDateRangeComponent,
    multi: true,
  }],
})
export class FormDateRangeComponent extends ElementBase<string> {
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public name: string;
  @Input() public disabled = false;
  @Input() public sm: false;
  @Input() public xs: false;

  @ViewChild(NgModel) model: NgModel;
  @ViewChild('input') input;

  public identifier = `form-date-range-${identifier++}`;
  public dateId = `dt${identifier++}`;
  public dateConfig = {
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: true
  };


  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private _localeService: BsLocaleService) {
    super(validators, asyncValidators);
    this._localeService.use('sv');
  }

  public markAsTouched() {
    this.model.control.markAsTouched();
  }
  public markAsDirty() {
    this.model.control.markAsDirty();
  }
  public markAsPristine() {
    this.model.control.markAsPristine();
  }
}

let identifier = 0;
