import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../shared/shared.module';
import { TimePostListComponent } from './time-post-list.component';
import { CustomFormsModule } from '../../../../shared/modules/custom-forms/custom-forms.module';
import { timeDecimalPipeModule } from '../../../../shared/pipes/time-decimal-pipe.module';
import { EditTimePostModalModule } from '../../../../shared/components/edit-timepost/edit-timepost-modal.module';

@NgModule({
  imports: [
    SharedModule,
    CustomFormsModule,
    timeDecimalPipeModule,
   EditTimePostModalModule


  ],
  declarations: [TimePostListComponent],
  exports: [TimePostListComponent]
})
export class TimePostListModule { }
