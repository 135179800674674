import { QueryList } from '@angular/core';

import { FormCheckComponent } from '../components/form-check/form-check.component';
import { FormDateComponent } from '../components/form-date/form-date.component';
import { FormSelectComponent } from '../components/form-select/form-select.component';
import { FormTextComponent } from '../components/form-text/form-text.component';


export class FormHelper {
    static touchForm(
        text: QueryList<FormTextComponent> = null,
        check: QueryList<FormCheckComponent> = null,
        select: QueryList<FormSelectComponent> = null,
        date: QueryList<FormDateComponent> = null) {

        if (text != null) {
            text.map(component => {
                component.markAsDirty();
                component.markAsTouched();
            });
        } if (check != null) {
            check.map(component => {
                component.markAsDirty();
            });
        } if (select != null) {
            select.map(component => {
                component.markAsDirty();
                component.markAsTouched();
            });
        }
        if (date != null) {
            date.map(component => {
                component.markAsDirty();
                component.markAsTouched();
            });
        }
    }
    static resetForm(
        text: QueryList<FormTextComponent> = null,
        check: QueryList<FormCheckComponent> = null,
        select: QueryList<FormSelectComponent> = null,
        date: QueryList<FormDateComponent> = null) {

        if (text != null) {
            text.map(component => {
                component.markAsPristine();
            });
        } if (check != null) {
            check.map(component => {
                component.markAsPristine();
            });
        } if (select != null) {
            select.map(component => {
                component.markAsPristine();
            });
        }
        if (date != null) {
            date.map(component => {
                component.markAsPristine();
            });
        }
    }
}
