import { Component, EventEmitter, Input, OnDestroy, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { TimePostService } from '../../services/timepost.service';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService, TimepickerConfig } from 'ngx-bootstrap';
import { FormTextComponent } from '../form-text/form-text.component';
import { FormDateComponent } from '../form-date/form-date.component';
import { FormSelectComponent } from '../form-select/form-select.component';
import { FormCheckComponent } from '../form-check/form-check.component';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ProjectService } from '../../services/project.service';
import { CustomerEnum } from '../../enums/customer-enum';
import { NgxSelectComponent } from 'ngx-select-ex';
import { CorporationEnum } from '../../enums/corporation-enum';
import { CurrencyEnum } from '../../enums/currency-enum';
import { ProjectTypeEnum } from '../../enums/project-type-enum';
import { WorkOrderEnum } from '../../enums/work-order-type-enum';
import { UserService } from '../../services/user.service';

export function getTimepickerConfig(): TimepickerConfig {
  return Object.assign(new TimepickerConfig(), {
    hourStep: 1,
    minuteStep: 1,
    secondsStep: 1,
    showMeridian: false,
    showSeconds: false,
    showSpinners: false,
  });
}

@Component({
  selector: 'app-edit-timepost-modal',
  templateUrl: './edit-timepost-modal.component.html',
  styleUrls: ['./edit-timepost-modal.component.scss'],
  providers: [{ provide: TimepickerConfig, useFactory: getTimepickerConfig}]
})
export class EditTimePostModalComponent implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  public workTypes = [] as Dto.WorkTypeDto[];
  public customerProjects = [] as Dto.ProjectDto[];
  public users = [] as Dto.UserDto[];

  public loading = true;

  public isNewTimePost = false;

  public timePost: Dto.TimePostDto;

  modalRef: BsModalRef;
  editPostForm: FormGroup;

  @ViewChildren(FormTextComponent) formTextComponents: QueryList<FormTextComponent>;
  @ViewChildren(FormDateComponent) formDateComponents: QueryList<FormDateComponent>;
  @ViewChildren(FormCheckComponent) formCheckComponents: QueryList<FormCheckComponent>;
  @ViewChildren(FormSelectComponent) formSelectComponents: QueryList<FormSelectComponent>;

  @ViewChild('template') template: TemplateRef<void>;

  constructor(
    private timePostService: TimePostService, 
    private projectService: ProjectService, 
    private modalService: BsModalService, 
    private toastr: ToastrService, 
    private fb: FormBuilder,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.editPostForm = this.fb.group({
      userId: ['', Validators.required],
      projectId: ['', Validators.required],
      workTypeId: ['', Validators.required],
      startDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required]
    }); 

    this.loadCustomerProjects();
    this.loadWorkTypes();
    this.loadUsers();
  }

  loadCustomerProjects() {
    this.projectService.getAllCustomerProjectSimple(CustomerEnum.Aros).subscribe((res: Dto.ProjectDto[]) => {
      this.customerProjects = res;
    });
  }

  loadWorkTypes() {
    this.timePostService.getWorkTypes().subscribe((res: Dto.WorkTypeDto[]) => {
      this.workTypes = res;
    });
  }

  loadUsers() {
    this.userService.getAllUsers()
    .subscribe((res: Dto.UserDto[]) => {
      this.users = res;
    });
  }

  closeModal(form) {
    if (form.valid)
      this.modalRef.hide();
    else {
      this.editPostForm.reset();
      this.modalRef.hide();
    }
  }

  openModal(newTimePost: boolean, timePost?: Dto.TimePostDto,) {
    this.loading = true;
    if (newTimePost){
      this.isNewTimePost = newTimePost;

      this.projectService.selectedProject = this.projectService.initProject() 
      this.timePost = this.timePostService.initTimePost();

      this.timePost.startDate = new Date();
      this.timePost.startDate.setHours(2, 0, 0, 0);
      this.timePost.endDate = new Date();
      this.timePost.endDate.setHours(2, 0, 0, 0);
    } else {
      this.timePost = timePost; 
    }

    this.setFormValues(this.timePost);
    this.modalRef = this.modalService.show(this.template, {class: 'modal-lg', keyboard: false});
  }

  setFormValues(timePost: Dto.TimePostDto) {
    //#region set times
    const startTime = new Date(timePost.startDate);
    startTime.setHours(this.getHour(timePost.startDate));
    startTime.setMinutes(this.getMinute(timePost.startDate))

    const endTime = new Date(timePost.endDate);
    endTime.setHours(this.getHour(timePost.endDate));
    endTime.setMinutes(this.getMinute(timePost.endDate))
    //#endregion

    this.editPostForm.patchValue({
      projectId: timePost.projectId,
      workTypeId: timePost.workTypeId,
      userId: timePost.userId,
      startDate: this.getDate(timePost.startDate),
      startTime: startTime,
      endDate: this.getDate(timePost.endDate),
      endTime: endTime,
    });

    if (timePost.locked) {
      this.editPostForm.get('startTime').disable();
      this.editPostForm.get('endTime').disable();
    } else {
      this.editPostForm.get('startTime').enable();
      this.editPostForm.get('endTime').enable();
    }
    this.loading = false;
  }

  deleteTimePost() {
    this.timePostService.removeTimePost(this.timePost.timePostId).subscribe({
      next: () => {
        this.toastr.success(`Raderade tidspost ${this.timePost.orderNumber}`);
        this.modalRef.hide();
        this.timePostService.notifyTimePostChanged();
      },
      error: (err) => {
        console.error('Error deleting time post', err);
        this.toastr.error('Kunde inte radera tidspost', 'Fel');
      }
    });
  }

  updateTimePost() {
    if (this.editPostForm.valid) {
      const formValue = this.editPostForm.value;

      const timePost = {
        ...this.timePost,
        workTypeId: formValue.workTypeId,
        projectId: formValue.projectId,
        userId: formValue.userId,
        startDate: this.getNewDate(formValue.startDate, formValue.startTime),
        endDate: this.getNewDate(formValue.endDate, formValue.endTime)
      } as Dto.TimePostDto;
      
      if (this.isNewTimePost) {
        this.timePostService.createTimePost(timePost).subscribe((res) => {
          console.log(res);
          this.timePostService.notifyTimePostChanged();
          this.modalRef.hide();
          this.toastr.success('Skapade tidspost');
        });
      } else {
        this.timePostService.saveTimePost(timePost).subscribe({
          next: () => {
            this.timePostService.notifyTimePostChanged();
            this.modalRef.hide();
            this.toastr.success('Uppdaterade tidspost');
          },
          error: (err) => {
            console.error('Error updating time post', err);
            this.toastr.error('Kunde inte uppdatera tidspost', 'Fel');
          }
        });
      }
    }
  }


  //#region Timehelpers
  getDate(dateTime: any): string {
    if (dateTime instanceof Date) {
      return dateTime.toISOString().split('T')[0];
    } else if (typeof dateTime === 'string') {
      return dateTime.split('T')[0];
    }
    return '';
  }

  getTime(dateTime: any): string {
    if (dateTime instanceof Date) {
      return dateTime.toString().split('T')[1].substring(0, 5) || '';
    } else if (typeof dateTime === 'string' ) {
      return dateTime.split('T')[1].substring(0, 5) || '';
    }
    return '';
  }

  getHour(date: Date): number {
    const time = this.getTime(date);
    return time ? parseInt(time.split(':')[0]) : 0;
  }

  getMinute(date: Date): number {
    const time = this.getTime(date);
    return time ? parseInt(time.split(':')[1]) : 0;
  }

  getNewDate(datePart, timePart){
    // Parse the date strings into Date objects
    const date1 = new Date(datePart);
    const date2 = new Date(timePart);

    // Extract date components from datePart
    const year = date1.getFullYear();
    const month = date1.getMonth(); 
    const day = date1.getDate();

    // Extract time components from timePart
    const hours = date2.getHours();
    const minutes = date2.getMinutes();
    const seconds = date2.getSeconds();
    const milliseconds = date2.getMilliseconds();

    // Create a new Date object with the combined components
    const combinedDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);
    
    return combinedDate;
  }
  //#endregion
  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();    
  }
}
