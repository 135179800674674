import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { TimeHelper } from '../helpers/time-helpers';
import { Observable, BehaviorSubject } from 'rxjs';
import { AccountService } from './account.service';

@Injectable()
export class UserService {

  private baseUrl = environment.apiUrl;
  private controllerName = 'User';

  public selectedUser = {} as Dto.UserDto;
  public selectedEducation = {} as Dto.UserEducationDto;

  public occupancyData: BehaviorSubject<Dto.UserOccupancyDataDto> = new BehaviorSubject<Dto.UserOccupancyDataDto>(undefined);

  constructor(private http: HttpClient, private accountService: AccountService) {
    
    if (this.accountService.loggedIn){
      this.updateOccupancy();
    }
  }


  updateOccupancy() {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 90);

    this.getUserOccupancyData(TimeHelper.ToUTCTime(fromDate), TimeHelper.ToUTCTime(new Date())).subscribe(res => {
      this.occupancyData.next(res);
    });
  }


  getUser(userId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/' + userId);
  }

  getAllUsers() {
    return this.http.get(this.baseUrl + this.controllerName + '/AllUsers');
  }

  getAllResources() {
    return this.http.get(this.baseUrl + this.controllerName + '/AllResources');
  }

  getUsersByRole(role: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/AllResourcesInRole/' + role);
  }

  createUser(data) {
    return this.http.post(this.baseUrl + this.controllerName + '/CreateUser', data);
  }


  getEducationsForCurrentUser(year: number) {
    return this.http.get(this.baseUrl + this.controllerName + '/GetEducationsForCurrentUser/' + year);
  }

  getEducationsForUser(userId: string, year: number) {
    return this.http.get(this.baseUrl + this.controllerName + '/GetEducationsForUser/' + userId + '/' + year);
  }

  getEducationById(userEducationId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/GetEducationById/' + userEducationId);
  }

  getAllEducationCards(year: number) {
    return this.http.get(this.baseUrl + this.controllerName + '/GetAllEducationCards/' + year);
  }

  createAllUserEducation(model: Dto.UserEducationDto) {
    model.date = TimeHelper.ToUTCTime(model.date);
    return this.http.post(this.baseUrl + this.controllerName + '/CreateAllUserEducation', model);
  }
  createUserEducation(model: Dto.UserEducationDto) {
    model.date = TimeHelper.ToUTCTime(model.date);
    return this.http.post(this.baseUrl + this.controllerName + '/CreateUserEducation', model);
  }
  updateUserEducation(model: Dto.UserEducationDto) {
    model.date = TimeHelper.ToUTCTime(model.date);
    return this.http.post(this.baseUrl + this.controllerName + '/UpdateUserEducation', model);
  }
  removeUserEducation(model: Dto.UserEducationDto) {
    return this.http.post(this.baseUrl + this.controllerName + '/RemoveUserEducation', model);
  }




  getFlexForUser(userId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/GetFlexForUser/' + userId);
  }
  getFlexForCurrentUser() {
    return this.http.get(this.baseUrl + this.controllerName + '/GetFlexForCurrentUser');
  }
  getAllFlexCards() {
    return this.http.get(this.baseUrl + this.controllerName + '/GetAllFlexCards');
  }



  getUserOccupancyData(from: Date, to: Date): Observable<any> {
    return this.http.post(this.baseUrl + this.controllerName + '/GetUserOccupancyData', { startDate: from, endDate: to });
  }
  getSpecificUserOccupancyData(from: Date, to: Date) {
    return this.http.post(this.baseUrl + this.controllerName + '/GetUserOccupancyData', { startDate: from, endDate: to });
  }

  getWageCurveList(): Observable<any> {
    return this.http.get(this.baseUrl + this.controllerName + '/GetWageCurveList');
  }


}
