import 'rxjs/add/observable/throw';
import { Router } from '@angular/router';


import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ServerErrorService } from '../services/server-error.service';
import { AccountService } from '../../shared/services/account.service';
import { AuthenticationService } from '../../shared/services/authentication.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private serverErrorService;
    
    constructor(private injector: Injector, private accountService: AccountService, private authenticationService : AuthenticationService, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .catch(error => {

                if (error.status === 403 || error.status === 401) {
                    this.authenticationService.logout(this.accountService.account.id);                    
                    this.router.navigate(['login']);
                }
                else if (error.status === 404)
                {
                    this.router.navigate(['overview']);
                    return;
                }

                this.serverErrorService = this.injector.get(ServerErrorService);
                this.serverErrorService.showError(error);

                return Observable.throwError(error);
            });
    }
}
