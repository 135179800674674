import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { AccountService } from '../../../shared/services/account.service';

@Component({
  selector: 'app-not-authorized-menu',
  templateUrl: './not-authorized-menu.component.html',
  styleUrls: ['./not-authorized-menu.component.scss']
})
export class NotAuthorizedMenuComponent implements OnInit {
  currentDate$: Observable<string>;
  currentTime$: Observable<string>;
  currentWeek: number;

  @Input() name: string;
  
  constructor(
    private datePipe: DatePipe,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.currentDate$ = timer(0, 60000).pipe(
      map(() => new Date()),
      map(date => formatDate(date, 'mediumDate', 'sv'))
    );

    this.currentTime$ = timer(0, 1000).pipe(
      map(() => new Date(), console.log('logged in: ', this.accountService.loggedIn, ' admin: ', this.accountService.isAdmin)),
      map(date => formatDate(date, 'shortTime', 'sv'))
    );

    this.currentWeek = this.getWeekNumber();
  }

  getWeekNumber() {
    let d = new Date();
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  }

}
