import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject, timer, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Injectable()
export class TimerService implements OnDestroy {
  public timeSubject: BehaviorSubject<string> = new BehaviorSubject('00:00');
  private destroy$: Subject<void> = new Subject();
  private timerSubscription: Subscription | null = null;
  private startDate: Date | null = null;

  constructor() {}

  startTimer(startDate: Date): Observable<string> {
    this.stopTimer(); // Stop and reset the timer before starting a new one

    // Ensure the start date is a Date object
    if (!(startDate instanceof Date)) {
      try {
        startDate = new Date(startDate); // Attempt to convert to Date
        if (isNaN(startDate.getTime())) {
          throw new Error("Invalid date");
        }
      } catch (error) {
        console.error("Invalid start date provided to startTimer:", startDate);
        return;
      }
    }

    // Ensure the start date is valid and reset it if necessary
    const currentTime = new Date();
    console.log('startdate', startDate, 'currentdate', currentTime);
    
    if (startDate.getTime() > currentTime.getTime()) {
      console.warn("Start date is in the future. Resetting to current time.");
      this.startDate = currentTime;
    } else {
      this.startDate = new Date(startDate);
    }
    
    this.timeSubject = new BehaviorSubject('00:00');
    this.destroy$ = new Subject();

    this.timerSubscription = timer(0, 1000).pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.startDate) {
        console.error("startDate is null during timer execution.");
        return;
      }

      const now = new Date();
      const timeDiff = Math.floor((now.getTime() - this.startDate.getTime()) / 1000);

      // Ensure that the time difference doesn't go negative
      const timeToDisplay = timeDiff >= 0 ? timeDiff : 0;

      this.timeSubject.next(this.formatTime(timeToDisplay));
    });

    return this.timeSubject.asObservable();
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    if (this.destroy$) {
      this.destroy$.next();
      this.destroy$.complete();
    }

    this.timeSubject.next('00:00'); // Reset the timer to 00:00
    this.startDate = null; // Clear the start date
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  }

  ngOnDestroy() {
    this.stopTimer();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
