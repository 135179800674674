import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

import { ElementBase } from '../../form';


@Component({
  selector: 'app-form-text',
  templateUrl: './form-text.component.html',
  styleUrls: ['./form-text.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormTextComponent,
    multi: true,
  }],
})
export class FormTextComponent extends ElementBase<string> implements OnInit {
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public name: string;
  @Input() public type = 'text';
  @Input() public disabled = false;
  @Input() public appendText: string;
  @Input() public sm = false;
  @Input() public xs = false;
  @Input() public validateMin: number;
  @Input() public validateMax: number;
  
  @ViewChild(NgModel) model: NgModel;
  @ViewChild('input') input;

  public identifier = `form-text-${identifier++}`;

  @Output() formBlur = new EventEmitter();
  @Output() formFocus = new EventEmitter();

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  public markAsTouched() {
    this.model.control.markAsTouched();
  }
  public markAsDirty() {
    this.model.control.markAsDirty();
  }
  public markAsPristine() {
    this.model.control.markAsPristine();
  }

  blur() {
    this.formBlur.emit(event);
  }

  focus() {
    this.input.nativeElement.focus();
    this.formFocus.emit(event);
  }


  ngOnInit() {
  }
}


let identifier = 0;
