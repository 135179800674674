import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Subject } from "rxjs";
// import { FormSelectComponent } from "../../shared/components/form-select/form-select.component";
import { AccountService } from "../../../shared/services/account.service";
import { TimePostService } from "../../../shared/services/timepost.service";
import { UserService } from "../../../shared/services/user.service";

@Component({
  selector: "app-time-logging",
  templateUrl: "./time-logging.component.html",
  styleUrls: ["./time-logging.component.scss"],
})
export class TimeLoggingComponent implements OnInit {
  resourcesInRoleList: Dto.ResourcesListDto[] = [];
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public timePostService: TimePostService,
    public userService: UserService,
    public accountService: AccountService
  ) {}

  ngOnInit() {
    this.timePostService.resetTimePeriod();
    // this.getResourcesList();
    this.getResourcesInRoleList();
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userService.getUser(localStorage.getItem("id"))
    .subscribe((res: Dto.UserDto) => {
      this.userService.selectedUser = res;
      console.log('res', res);
    })
  }



  getResourcesInRoleList() {
    this.userService
    .getUsersByRole('Projectmanager')
    .takeUntil(this.unsubscribe)
    .subscribe((result: Dto.ResourcesListDto[]) => {
      this.resourcesInRoleList = result;
    });
  }

}
