import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { environment } from '../../../environments/environment';
import { TimeHelper } from '../helpers/time-helpers';
import { AccountService } from './account.service';
import { UserService } from './user.service';
import { WorkOrderEnum } from '../enums/work-order-type-enum';

@Injectable()
export class TimePostService {
  private baseUrl = environment.apiUrl;
  private controllerName = 'TimePost';

  public settings = {
    minNumber: 150,
    maxNumber: 9999,

  }

  public currentlyCheckedInTimeposts: Dto.TimePostDto[] = [];

  public highlightedPostId: any = -1;
  public editedPostObject: any;

  public timePostHistoryList: BehaviorSubject<Dto.TimePostGroupDto[]> = new BehaviorSubject<Dto.TimePostGroupDto[]>([]);
  public timePostHistoryUngrouped: BehaviorSubject<Dto.TimePostDto[]> = new BehaviorSubject<Dto.TimePostDto[]>([]);
  public projectTimePostHistoryList: BehaviorSubject<Dto.TimePostGroupDto[]> = new BehaviorSubject<Dto.TimePostGroupDto[]>([]);
  public timePostSummaryList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public timePeriodSubject: BehaviorSubject<Date[]> = new BehaviorSubject<Date[]>([]);
  public projectTimePeriodSubject: BehaviorSubject<Date[]> = new BehaviorSubject<Date[]>([]);
  public timePeriod: Date[];
  public timePosts: Dto.TimePostListDto[];
  public timePostHistory: Dto.TimePostGroupDto[];
  public timePostSummary: any[];

  private timePostChangedSource = new Subject<void>();
  timePostChanged$ = this.timePostChangedSource.asObservable();

  public selectedResource: string = ""

  public mapped_timePostHistoryList = this.timePostHistoryList.asObservable();
  public mapped_timePeriodSubject = this.timePeriodSubject.asObservable();
  public mapped_projectTimePeriodSubject = this.projectTimePeriodSubject.asObservable();
  public mapped_timePostSummaryList = this.timePostSummaryList.asObservable();
  public mapped_projectTimePostHistoryList = this.projectTimePostHistoryList.asObservable();
  public mapped_projectTimePostHistoryUngrouped = this.timePostHistoryUngrouped.asObservable();

  public timePostHistoryLoading = true;

  constructor(private http: HttpClient, private userService: UserService) {
    // set initial timeperiod to use
    this.resetTimePeriod();
    this.timePeriodSubject.subscribe(res => {
      this.timePeriod = res;
    });

    this.timePostSummaryList.subscribe(res => {
      this.timePostSummary = res;
    });
  }

  initTimePost() : Dto.TimePostDto {
    return {
      userId: undefined,
      projectId: undefined,
      locked: false,
      amount: 0,
      endDate: undefined,
      startDate: undefined,
      orderNumber: undefined,
      timePostId: undefined,
      workTypeId: WorkOrderEnum.Carpentry,
    } as Dto.TimePostDto;
  }

  notifyTimePostChanged() {
    this.timePostChangedSource.next();
  }

  resetTimePeriod() {
    const timePeriodStart = new Date(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const timePeriodEnd = new Date(moment().add(1, 'days').format('YYYY-MM-DD'));
    this.timePeriodSubject.next([timePeriodStart, timePeriodEnd]);
    this.projectTimePeriodSubject.next([timePeriodStart, timePeriodEnd]);
  }

  loadTimePosts(dateSpan: Date[]) {
    this.timePostHistoryLoading = true;

    console.log('timeposts for ', this.selectedResource);
    
    this.getTimePostGroupsForUser(dateSpan).subscribe((res: Dto.TimePostGroupDto[]) => {
      this.timePostHistoryList.next(res);
      this.timePostHistoryLoading = false;
    });
  }

  loadTimePostsUngrouped(dateSpan: Date[]) {
    this.timePostHistoryLoading = true;

    console.log('timespan for ungrouped', dateSpan);
    
    this.getAllTimePostsById(dateSpan).subscribe((res: Dto.TimePostDto[]) => {
      this.timePostHistoryUngrouped.next(res);
      console.log('res from ungrouped', res);
      
      this.timePostHistoryLoading = false;
    })
  }

  sendAttestEmail(timePostId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/SendAttestEmail/' + timePostId);
  }

  loadTimePostsForAttestant(dateSpan: Date[], attestantId: string) {
    this.timePostHistoryLoading = true;
    this.getTimePostGroupsForAttestant(attestantId, dateSpan).subscribe((res: Dto.TimePostGroupDto[]) => {
      this.timePostHistoryList.next(res);
      this.timePostHistoryLoading = false;
    });
  }

  loadTimePostsForProject(projectId: string, dateSpan: Date[], projectTagId: string) {
    this.timePostHistoryLoading = true;
    this.getTimePostGroupsForProject(projectId, dateSpan, projectTagId).subscribe((res: Dto.TimePostGroupDto[]) => {
      this.projectTimePostHistoryList.next(res);
      this.timePostHistoryLoading = false;
      console.log(this.projectTimePostHistoryList.value);
      
    });
  }

  getTimePostGroupsForAttestant(attestantId: string, dateSpan: Date[]) {
    return this.http.post(this.baseUrl + this.controllerName + '/GetTimePostGroupsForAttestant/' + attestantId, 
    { startDate: TimeHelper.ToUTCTime(dateSpan[0]), endDate: TimeHelper.ToUTCTime(dateSpan[1])});
  }

  getTimePostGroupsForUser(dateSpan: Date[]) {    
    return this.http.post(this.baseUrl + this.controllerName + '/GetTimePostGroupsForUser',
      { startDate: TimeHelper.ToUTCTime(dateSpan[0]), endDate: TimeHelper.ToUTCTime(dateSpan[1]), resourceId: this.selectedResource });
  }

  getTimePostGroupsForProject(projectId: string, dateSpan: Date[], projectTagId: string) {
    return this.http.post(this.baseUrl + this.controllerName + '/GetTimePostGroupsForProject',
      { projectId: projectId, startDate: TimeHelper.ToUTCTime(dateSpan[0]), endDate: TimeHelper.ToUTCTime(dateSpan[1]), projectTagId: projectTagId });
  }

  getTimePostById(timePostId: string){
    return this.http.get(this.baseUrl + this.controllerName + '/GetTimePostById/' + timePostId);
  }

  getAllTimePosts(PagingDto: Dto.SearchPagingDto){
    console.log('PagingDTO', PagingDto);
    
    return this.http.post(this.baseUrl + this.controllerName + '/GetAllTimePosts', PagingDto);
  }

  getAllTimePostsById(dateSpan?: Date[]): Observable<Dto.TimePostDto[]>{
    return this.http.post<Dto.TimePostDto[]>(this.baseUrl + this.controllerName + '/GetAllTimePostsById/', 
      {userId: this.selectedResource, startDate: TimeHelper.ToUTCTime(dateSpan[0]), endDate: TimeHelper.ToUTCTime(dateSpan[1])});
  }

  getWorkTypes() : Observable<Dto.WorkTypeDto[]> {
    return this.http.get<Dto.WorkTypeDto[]>(this.baseUrl + this.controllerName + '/GetWorkTypes');
  }

  saveTimePost(timePost: Dto.TimePostDto) {
    return this.http.post<Dto.TimePostDto>(this.baseUrl + this.controllerName + '/SaveTimePost', timePost);
  }

  createTimePost(timepost: Dto.TimePostDto) {
    return this.http.post(this.baseUrl + this.controllerName + '/CreateTimePost', timepost);
  }

  saveTimePosts(timePosts: Dto.TimePostListDto[]) {
    timePosts.forEach(item => {
      if (item.isDateRange) {
        item.dateRangeTo = TimeHelper.ToUTCTime(item.date[1]);
        item.date = TimeHelper.ToUTCTime(item.date[0]);
        item.resourceId = this.selectedResource;
      } else {
        item.date = TimeHelper.ToUTCTime(item.date);
        item.resourceId = this.selectedResource;
      }
    });
    return this.http.post(this.baseUrl + this.controllerName + '/SaveTimePosts', timePosts);
  }

  lockTimePost(timePostId: string, attestant: string) {
    
    return this.http.post(this.baseUrl + this.controllerName + '/LockTimePost/' + timePostId + '/' + attestant, {});
  }

  removeTimePost(timePostId: string) {
    return this.http.delete(this.baseUrl + this.controllerName + '/' + timePostId);
  }

  getFastOrderNumber() {
    return this.http.get(this.baseUrl + this.controllerName + '/GetFastOrderNumber');
  }

  exportToExcel(dateSpan: Dto.ProjectTimeSpanDto, projectName: string): Observable<any> {
    const newDateSpan = {
      startDate: TimeHelper.ToUTCTime(dateSpan.startDate),
      endDate: TimeHelper.ToUTCTime(dateSpan.endDate),
      projectId: dateSpan.projectId,
      projectTagId: dateSpan.projectTagId,
      userId: this.selectedResource ? this.selectedResource : this.userService.selectedUser.id
    } as Dto.ProjectTimeSpanDto;

    const headers = new HttpHeaders(); // additional headers in here
    return this.http.post(this.baseUrl + this.controllerName + '/ExportTimePostHistory', newDateSpan, {
      headers: headers,
      responseType: 'blob' // this line being the important part from the previous answer (thanks for that BTW Martin)
    }).map(
      res => { 
        const x = res;
        if (res) {
          const filename = 'Tidredovisning för ' + projectName + '.xlsx';
          saveAs(x, filename);
        }
        return true;
      },
      err => {
        return true;
      }
    );
  }
}
