
export class TimeHelper {
    static ToUTCTime(date: Date) {
        console.log('timehelper', date);
        
        if (typeof date === 'string') {
            date = new Date(date);
        }
        return new Date(date.toLocaleDateString('sv-SE'));
    }
}
