import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs-compat';
import { CheckInService } from '../../shared/services/checkIn.service';
import { ProjectService } from '../../shared/services/project.service';
import { ProjectTypeEnum } from '../../shared/enums/project-type-enum';
import { TimePostService } from '../../shared/services/timepost.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  private unsubscribe: Subject<void> = new Subject();

  checkInStatus: Map<string, Dto.TimePostDto> = new Map();

  resourcesList: any[] = []; 
  columnSizes: any[] = [];
  columnCount: number = 0;

  private specialOrderIds: string[] = [];

  public isLoading = true;
  public isConnected = false;
  
  constructor(
    private router: Router,
    private userService: UserService,
    private checkInService: CheckInService,
    private projectService: ProjectService,
    private timepostService: TimePostService,
  ) { }

  ngOnInit() {
    this.checkInService.connected$.pipe(takeUntil(this.unsubscribe)).subscribe(connected => {
      this.isConnected = connected;
      console.log('connection status', this.isConnected);
      
    });

    this.checkInService.getStatus().pipe(takeUntil(this.unsubscribe)).subscribe(status => {
      console.log("Status overview", status);
      
      this.checkInStatus = status;
    });

    this.userService.getAllUsers()
    .subscribe((result: any[]) => {
      this.resourcesList = result;
      this.calculateColumnSizes();
      this.isLoading = false;
    });

    if (!this.projectService.specialOrderIds) {
      this.projectService.getAllProjectByProjectType(ProjectTypeEnum.Inactive)
      .subscribe((res: Dto.ProjectDto[]) => {
        this.projectService.specialOrderIds = res.map(p => p.projectId);
      });
    }
  }

  checkInColor(userId: string): string {
    const timepostId = this.checkInStatus.get(userId);
    
    if (timepostId)
      return this.specialOrderIds.includes(timepostId.projectId) ? 'bg-secondary' : 'bg-success'
    else if (this.checkInStatus.has(userId)) {
      return 'bg-success'
    } else {
      return 'bg-danger'
    }
  }
  
  navigate(id: any) {
    this.router.navigate(['check-in', id], );
  }

  getColumnStartIndex(columnIndex: number): number {
    let startIndex = 0;
    for (let i = 0; i < columnIndex; i++) {
      startIndex += this.columnSizes[i];
    }
    return startIndex;
  }

  getColumnEndIndex(columnIndex: number): number {
    return this.getColumnStartIndex(columnIndex) + this.columnSizes[columnIndex];
  }

  calculateColumnSizes() {
    const totalItems = this.resourcesList.length;
    const maxItemsPerColumn = 4;

    this.columnCount = Math.ceil(totalItems / maxItemsPerColumn);

    const itemsPerColumn = Math.ceil(totalItems / this.columnCount);

    for (let i = 0; i < this.columnCount - 1; i++) {
      this.columnSizes.push(itemsPerColumn);
    }

    this.columnSizes.push(totalItems - (itemsPerColumn * (this.columnCount - 1)));
  }

  getColumnItems(list: any[], startIndex: number, endIndex: number) {
    return list.slice(startIndex, endIndex);
  }

  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();    
  }
}
