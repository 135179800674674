import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit {
  @Input() text: string;
  @Input() sm = false;
  @Input() xs = false;
  @Input() white = false;

  constructor() { }

  ngOnInit() {
  }

}
