import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { validate } from '../../form';

@Directive({
  selector: '[validateMinlength][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinlengthValidatorDirective),
      multi: true
    }
  ]
})
export class MinlengthValidatorDirective implements Validator {
  @Input('validateMinlength') minlength: number;

  validate(control: AbstractControl): ValidationErrors | null {    
    
    if (!control.value || control.value.length >= this.minlength) {
      return null;
    }
    return {
      minlength: {
        requiredLength: this.minlength,
        actualLength: control.value.length
      }
    };
  }
}
