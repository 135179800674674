import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {
    modalRef: BsModalRef;
    message: string;

    @Input() confirmText = 'Är du säker?';
    @Output() confirmed = new EventEmitter();
    @Output() declined = new EventEmitter();
    @ViewChild('template') template: ElementRef;

    constructor(private modalService: BsModalService) { }

    openModal() {
        this.modalRef = this.modalService.show(this.template, { class: 'modal-sm' });
    }

    confirm(): void {
        this.confirmed.emit();
        this.modalRef.hide();
    }

    decline(): void {
        this.declined.emit();
        this.modalRef.hide();
    }
}
