import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { Observable, Subject, combineLatest, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProjectService } from '../../shared/services/project.service';
import { CustomerService } from '../../shared/services/customer.service';
import { TimePostService } from '../../shared/services/timepost.service';
import { CheckInService } from '../../shared/services/checkIn.service';
import { TimerService } from '../../shared/services/timer.service';
import { Form, FormControl, NgForm } from '@angular/forms';
import { MinValidatorDirective } from '../../shared/directives/validator-min/validator-min.directive';
import { WorkOrderEnum } from '../../shared/enums/work-order-type-enum';
import { CustomerEnum } from '../../shared/enums/customer-enum';
import { CurrencyEnum } from '../../shared/enums/currency-enum';
import { CorporationEnum } from '../../shared/enums/corporation-enum';
import { ProjectTypeEnum } from '../../shared/enums/project-type-enum';
import { TimeHelper } from '../../shared/helpers/time-helpers';
import { ToastrService } from 'ngx-toastr';
import { NgxSelectComponent } from 'ngx-select-ex';
import { FastOrderModalComponent } from './fast-order-modal/fast-order-modal.component';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss']
})
export class CheckInComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  
  public project: Dto.ProjectDto;
  public timePost: Dto.TimePostDto;
  public user: Dto.UserDto;
  public foundProject: Dto.ProjectDto;

  public loading = true;
  public isCheckedIn = false;
  public isWorking = false;
  public isFastOrder = false;
  public orderNumberCheck: boolean = false; // Track checkbox state

  public displayTime: string;

  // public customerProjects: Dto.ProjectDto[] = [];
  public workTypes: Dto.WorkTypeDto[] = [];
  public specialProjects: Dto.ProjectDto[] = [];
  
  public checkInStatus = new Map<string, {timepostId: string, projectId: string}>();

  @ViewChild('checkInForm') checkInForm : NgForm;
  @ViewChild('FastOrderModal') FastOrderModal: FastOrderModalComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private projectService: ProjectService,
    private timePostService: TimePostService,
    private checkInService: CheckInService,
    private timerService: TimerService,
    private toastService: ToastrService
  ) {}

  ngOnInit() {
    //Sätts för historik
    this.userService.selectedUser.id = this.route.snapshot.paramMap.get('id');
    this.timePostService.selectedResource = this.route.snapshot.paramMap.get('id');
    
    combineLatest([
      this.loadWorkTypes(),
      this.loadSpecialOrders(),
      this.initializeSelectedProject()
    ])
    .pipe(
      switchMap(async () => this.subscribeToCheckInStatus()),
      takeUntil(this.unsubscribe$)
    )
    .subscribe({
      next: () => this.loading = false, 
    });
  }
  
  private loadWorkTypes() {
    return this.timePostService.getWorkTypes()
    .pipe(
      tap((res: Dto.WorkTypeDto[]) => {
        this.workTypes = res;
      })
    );
  }

  private loadSpecialOrders() {
    return this.projectService.getAllProjectByProjectType(ProjectTypeEnum.Inactive)
    .pipe(
      tap((res: Dto.ProjectDto[]) => {
        this.specialProjects = res;
      })
    );
  }
  
  @ViewChild('Test') ngxSelect: NgxSelectComponent;
  private initializeSelectedProject() {
    return this.userService.getUser(this.userService.selectedUser.id)
    .pipe(
      tap((res: Dto.UserDto) => {
        this.userService.selectedUser = res;
        this.project = this.projectService.initProject();
        this.project.userId = this.userService.selectedUser.id;
        this.timePost = this.timePostService.initTimePost();

        this.timePost.userId = this.userService.selectedUser.id;
        this.timePost.workTypeId = this.userService.selectedUser.lastWorkTypeId != null ? this.userService.selectedUser.lastWorkTypeId : WorkOrderEnum.Carpentry;
        
        if (this.ngxSelect) {
          this.ngxSelect.defaultValue.push(this.timePost.workTypeId); 
        }
      })
    ); 
  }
  
  private startTimer(startDate: Date) {
    this.timerService.startTimer(startDate).pipe(
    takeUntil(this.unsubscribe$))
    .subscribe();
  }

  private subscribeToCheckInStatus() {
    this.checkInService.getStatus()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((res: Map<string, Dto.TimePostDto>) => {
      const { isWorking, isCheckedIn, timepostId} = this.checkInService.processStatus(res, this.userService.selectedUser.id);
      this.isWorking = isWorking;
      this.isCheckedIn = isCheckedIn;

      if (isWorking && timepostId) {
        const userTimepost = res.get(this.userService.selectedUser.id);

        this.projectService.getProject(userTimepost.projectId).subscribe((res: Dto.ProjectDto) => {
          this.project = res;
          this.timePost = userTimepost;
          console.log(res);
        })        

        this.timerService.startTimer(userTimepost.startDate);

      } else {
        console.log('init projects');
        
        this.projectService.initProject();
        this.timerService.stopTimer();
      }
    })
  }

  public saveTimePost() {
    this.checkProject()
    .pipe(
      switchMap(() => {
        if (this.timePost.startDate) {
          this.timePost.endDate = new Date();
        }
        else {
          this.timePost.startDate = new Date();
        }
        
        return this.timePostService.saveTimePost(this.timePost);
      }),
      catchError(err => {
        this.checkInService.checkOutOrder(this.userService.selectedUser.id);
        this.timerService.stopTimer();
        this.checkInForm.resetForm();
        this.isWorking = false;
        this.ngOnInit(); 
        // this.toastService.error('Sparade inte tidsposten, tiden var för kort', err);
        return throwError(() => new Error("Tidspostens totala tid var för kort och togs bort"));
      })
    )
    .subscribe({
      next: (res: Dto.TimePostDto) => {
        this.timePost = res;
        this.timePostService.currentlyCheckedInTimeposts.push(this.timePost);

        if (!this.isWorking) {
          if (this.orderNumberCheck) {
            this.openTimePostModal();
          }
          this.checkInService.checkIn(this.userService.selectedUser.id, this.timePost);
          this.startTimer(res.startDate);
          this.navigateHome();
        } else {
          this.checkInService.checkOutOrder(this.userService.selectedUser.id);
          this.timerService.stopTimer();
          this.checkInForm.resetForm();
          this.isWorking = false;
          this.ngOnInit();
        }
      }
    });
  }

  private checkProject(): Observable<void> {
    return this.projectService.getProjectByOrderNumber(this.project.orderNumber).pipe(
      switchMap((foundProject: Dto.ProjectSimpleDto | null) => {
        // Check if the project is found and active
        if (foundProject && !foundProject.active) {
          return throwError(() => new Error('Kan inte stämpla in på en inaktiv order'));
        }
  
        // If project not found, create a new one
        if (!foundProject) {
          return this.projectService.createProject(this.project).pipe(
            tap((res: Dto.ProjectDto) => {
              this.projectService.customerProjects.push(res);
              this.timePost.projectId = res.projectId;
            }),
            map(() => void 0)  // Map to `void` because we expect `Observable<void>`
          );
        }
  
        // If project found, just set its projectId on the timePost
        this.timePost.projectId = foundProject.projectId;
        return of(void 0);  // Return `Observable<void>` to match the expected type
      })
    );
  }

  public checkIn(){
    if (!this.isCheckedIn){
      this.saveTimePost();
      this.navigateHome();
    }else {
      this.isCheckedIn = false;
      this.isWorking = false;
      this.checkInService.goHome(this.userService.selectedUser.id);
      this.navigateHome();
    }
  }

  private fastOrder() {
    if (this.orderNumberCheck) {
      this.timePostService.getFastOrderNumber()
      .subscribe((res: any) => {
        console.log(res);
        this.project.orderNumber = res;
      });
    } else {
      this.project.orderNumber = undefined;
    }
  }

  public toggleCheckbox() {
    this.orderNumberCheck = !this.orderNumberCheck;
    this.fastOrder();
  }

  public formHasValues(form: NgForm): boolean {
    const values = form.form.value;
    // console.log('form values', values);
    
    switch (true) {
      case !values.Ordernumber:
        // console.log('Order is invalid: Order is required.');
        return false;
  
      case values.Ordernumber.length < 1:
        // console.log('Order is invalid: Order length is less than 1.');
        return false;
  
      case !values.workTypes:
        // console.log('WorkTypes is invalid: workTypes is required.');
        return false;
  
      default:
        return true;
    }
  }
  
  openTimePostModal(){
    this.FastOrderModal.openModal();
  }

  public selectedSpecialOrder(event: any) {
    console.log('special order', event);
    this.project.orderNumber = event;
    this.ngxSelect.optionValueField = WorkOrderEnum.Other;
  }
  
  public navigateHome() {
    this.router.navigate(['overview']);
  }

  public navigateHistory() {
    this.router.navigate(['check-in-history', this.userService.selectedUser.id]);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
