import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[validateMin][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinValidatorDirective),
      multi: true
    }
  ]
})
export class MinValidatorDirective implements Validator {
  @Input('validateMin') min: number;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.min != null ? minNumberValidator(this.min)(control) : null;
  }
}

export function minNumberValidator(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = Number(control.value);
    if (!isNaN(value) && value < min) {
      return {
        minnumber: {
          requiredMin: min,
          actualValue: value
        }
      };
    }
    return null;
  };
}
