import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[validateMax][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxValidatorDirective),
      multi: true
    }
  ]
})
export class MaxValidatorDirective implements Validator {
  @Input('validateMax') min: number;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.min != null ? MaxNumberValidator(this.min)(control) : null;
  }
}

export function MaxNumberValidator(max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = Number(control.value);
    if (!isNaN(value) && value > max) {
      return {
        maxnumber: {
          requiredMax: max,
          actualValue: value
        }
      };
    }
    return null;
  };
}
