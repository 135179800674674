import { NgModel } from '@angular/forms';
import { Attribute, Directive, Input } from '@angular/core';
import {
    NG_VALIDATORS,
    AbstractControl,
} from '@angular/forms';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: EqualValidatorDirective, multi: true }
    ]
})
export class EqualValidatorDirective {
     @Input('validateEqual') public validateEqual: NgModel;
    constructor() { }
    validate(c: AbstractControl): { [validator: string]: boolean } {
        // self value (e.g. retype password)
        const v = c.value;

        // value not equal
        if (this.validateEqual && v !== this.validateEqual.value) {
            return {
                notequal: false
            };
        }
        return null;
    }
}
