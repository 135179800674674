import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'app-check-in-history',
  templateUrl: './check-in-history.component.html',
  styleUrls: ['./check-in-history.component.scss']
})
export class CheckInHistoryComponent implements OnInit {

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {
    console.log('history test');    
  }

  public navigateUser() {
    this.router.navigate(['check-in', this.userService.selectedUser.id]);
  }

}
