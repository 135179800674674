import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FormCheckComponent } from '../../components/form-check/form-check.component';
import { FormDateRangeComponent } from '../../components/form-date-range/form-date-range.component';
import { FormDateComponent } from '../../components/form-date/form-date.component';
import { FormSelectComponent } from '../../components/form-select/form-select.component';
import { FormTextComponent } from '../../components/form-text/form-text.component';
import { FormValidationComponent } from '../../components/form-validation/form-validation.component';
import { CheckboxValidatorDirective } from '../../directives/validator-checkbox/validator-checkbox.directive';
import { EqualValidatorDirective } from '../../directives/validator-equal/validator-equal.directive';
import { MaxValidatorDirective } from '../../directives/validator-max/validator-max.directive';
import { MinValidatorDirective } from '../../directives/validator-min/validator-min.directive';
import { NgxSelectValidatorDirective } from '../../directives/validator-ngx-select/validator-ngx-select.directive';
import { SharedModule } from '../../shared.module';
import { EditTimePostModalComponent } from '../../components/edit-timepost/edit-timepost-modal.component';
import { MinlengthValidatorDirective } from '../../directives/validator-min-length/validator-min-length.directive';

@NgModule({
  imports: [
    FormsModule,
    SharedModule,
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    FormTextComponent,
    FormCheckComponent,
    FormSelectComponent,
    FormDateComponent,
    FormDateRangeComponent,
    FormValidationComponent,
    CheckboxValidatorDirective,
    EqualValidatorDirective,
    MinValidatorDirective,
    MaxValidatorDirective,
    MinlengthValidatorDirective,
    NgxSelectValidatorDirective
  ],
  exports: [
    FormTextComponent,
    FormCheckComponent,
    FormSelectComponent,
    FormDateComponent,
    FormDateRangeComponent,
    FormValidationComponent,
    CheckboxValidatorDirective,
    EqualValidatorDirective,
    MinValidatorDirective,
    MaxValidatorDirective,
    MinlengthValidatorDirective,
    NgxSelectValidatorDirective
  ]
})
export class CustomFormsModule { }
