import { NgModule } from '@angular/core';

import { CustomFormsModule } from '../../shared/modules/custom-forms/custom-forms.module';
import { SharedModule } from '../../shared/shared.module';
import { LoginModalComponent } from './login-modal.component';

@NgModule({
  imports: [
    SharedModule,
    CustomFormsModule
  ],
  declarations: [
    LoginModalComponent
  ]
})
export class LoginModalModule { }
