import { NgModule } from '@angular/core';

import { CommonModule } from '../../../../../node_modules/@angular/common';
import { LoadingBarComponent } from './loading-bar.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LoadingBarComponent
  ],
  exports: [
    LoadingBarComponent
  ]
})
export class LoadingBarModule { }
