import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/Subject';

import { ConfirmModalComponent } from '../../../../shared/components/confirm-modal/confirm-modal.component';
import { FormCheckComponent } from '../../../../shared/components/form-check/form-check.component';
import { FormDateComponent } from '../../../../shared/components/form-date/form-date.component';
import { FormSelectComponent } from '../../../../shared/components/form-select/form-select.component';
import { FormTextComponent } from '../../../../shared/components/form-text/form-text.component';
import { FormHelper } from '../../../../shared/helpers/form-helpers';
import { ProjectService } from '../../../../shared/services/project.service';
import { TimePostService } from '../../../../shared/services/timepost.service';
import { UserService } from '../../../../shared/services/user.service';
import { TimePostExceptionModalComponent } from './time-post-exception-modal/time-post-exception-modal.component';

@Component({
  selector: 'app-time-post-create',
  templateUrl: './time-post-create.component.html',
  styleUrls: ['./time-post-create.component.scss']
})
export class TimePostCreateComponent implements OnInit, OnDestroy {
  @Input() public resourcesList: Dto.ResourcesListDto[];
  

  private unsubscribe: Subject<void> = new Subject();

  public timePosts: Dto.TimePostListDto[];
  // public resourcesList: Dto.ResourcesListDto[] = [];
  public resourcesInRoleList: Dto.ResourcesListDto[] = [];
  public timePostHistory: Dto.TimePostGroupDto[];
  public userProjects: Dto.ProjectListDto[];
  public flagBadChar: Boolean = false;
  public flagBadLength: Boolean = false;
  public timeCategoryTagList: Array<any> = [{value: 'NORMALTID'}, {value: 'OB1'}, {value: 'OB2'},{value: 'OB3'},{value: 'ÖVERTID'}];


  private itemToDelete: Dto.TimePostListDto;
  private timePeriod: Date[];

  private timePostHistoryListSub: Subscription;
  private timePeriodSub: Subscription;
  private userProjectListSub: Subscription;

  @ViewChildren(FormTextComponent) formTextComponents: QueryList<FormTextComponent>;
  @ViewChildren(FormCheckComponent) formCheckComponents: QueryList<FormCheckComponent>;
  @ViewChildren(FormDateComponent) formDateComponents: QueryList<FormDateComponent>;
  @ViewChildren(FormSelectComponent) formSelectComponents: QueryList<FormSelectComponent>;

  @ViewChild('confirmDeleteModal') confirmDeleteModal: ConfirmModalComponent;
  @ViewChild('infoModal') infoModal: TimePostExceptionModalComponent;

  constructor(private projectService: ProjectService, public timePostService: TimePostService,
    private userService: UserService, private toastr: ToastrService) {
      
    this.resetTimePosts();
    this.timePostHistoryListSub = this.timePostService.mapped_timePostHistoryList.subscribe(res => {
      this.timePostHistory = this.deepClone(res);
    });
    this.userProjectListSub = this.projectService.mapped_userProjectList.subscribe(res => {
      this.userProjects = res;
    });
    this.timePeriodSub = this.timePostService.mapped_timePeriodSubject.subscribe(res => {
      this.timePeriod = res;
    });


  }

  ngOnInit() {
    // this.getResourcesList(); 
    this.getResourcesInRoleList();   
  }


  projectChanged(event: any, index: number){
    let userId = event[0].data.userId;
    this.timePostService.timePosts[index].attestUserId = userId;
  }

  getResourcesList() {
    this.userService
      .getAllResources()
      .takeUntil(this.unsubscribe)
      .subscribe((result: Dto.ResourcesListDto[]) => {
        this.resourcesList = result;
      });
    }

    getResourcesInRoleList() {
      this.userService
      .getUsersByRole('Projectmanager')
      .takeUntil(this.unsubscribe)
      .subscribe((result: Dto.ResourcesListDto[]) => {
        this.resourcesInRoleList = result;
      });
    }
  changeDescription(item2: Dto.TimePostDto) {
    setTimeout(() => {

      const item = this.timePostService.timePosts[0];
      const lenDescr: Number = item.description.length;
      // item.description = item.description.replace(/"/g, '').replace(/“/g, '').replace(/”/g, '')
      // .replace(/\\/g, '').replace(/-/g, '').replace(/,/g, '').replace(/\t/g, '');

      // if (lenDescr !== item.description.length) {
      //   this.flagBadChar = true;
      //   setTimeout(() => {
      //     this.flagBadChar = false;
      //   }, 4000);
      // }

      if (item.description.length > 43) {
        this.flagBadLength = true;
        item.description = item.description.substring(0, 43);
        setTimeout(() => {
          this.flagBadLength = false;
        }, 4000);
      } else if (item2.description.length > 43) {
        this.flagBadLength = true;
        item2.description = item2.description.substring(0, 43);
        setTimeout(() => {
          this.flagBadLength = false;
        }, 4000)
      }
    }, 100);
  }

  addTimePost() {
    let date = new Date();
    const length = this.timePostService.timePosts.length;
    if (length > 0) {
      date = !this.timePostService.timePosts[length - 1].isDateRange ? this.timePostService.timePosts[length - 1].date : new Date();
    }

    this.timePostService.timePosts.push(
      {
        timePostId: undefined, date: date, dateRangeTo: null, isDateRange: false, amount: 0,
        projectId: '', projectName: '', description: '', userId: undefined, meeting: false, addition: false, projectTagId: null,
        attestUserId: null, timeCategory: null
      } as Dto.TimePostListDto);

  }

  addRangeTimePost() {
    const date = null;

    this.timePostService.timePosts.push(
      {
        timePostId: undefined, date: date, dateRangeTo: null, isDateRange: true, amount: 0,
        projectId: '', projectName: '', description: '', userId: undefined, meeting: false, addition: false, projectTagId: null, 
        attestUserId: null, timeCategory: null
      } as Dto.TimePostListDto);
  }

  copyTimePost(post: Dto.TimePostListDto) {
    const newPost = JSON.parse(JSON.stringify(post));
    newPost.timePostId = null;
    newPost.date = new Date(newPost.date);
    this.timePostService.timePosts.push(newPost);
  }

  getTimeCategoryTags() {
    
    return this.timeCategoryTagList;
  }

  getUserProjectTags(projectId) {
    return this.userProjects.find(x => x.projectId === projectId).projectTags;
  }

  hasProjectTags(projectId) {
    return this.userProjects.find(x => x.projectId === projectId).projectTags.length > 0;
  }

  hasAttestTags(projectId) {
    return this.userProjects.find(x => x.projectId === projectId).attestTags.length > 0;
  }

  removeTimePost(item) {
    this.timePostService.timePosts = this.timePostService.timePosts.filter(x => x !== item);
  }

  openConfirmDeleteModal(item) {
    this.itemToDelete = item;
    this.confirmDeleteModal.openModal();
  }

  projectExists(projectId: string) {
    return this.userProjects.find(x => x.projectId === projectId) !== undefined || projectId === '';
  }

  deleteSelectedTimePost() {
    this.timePostService.removeTimePost(this.itemToDelete.timePostId).takeUntil(this.unsubscribe).subscribe(res => {
      this.timePostService.timePosts = this.timePostService.timePosts.filter(x => x !== this.itemToDelete);
      this.timePostService.loadTimePosts(this.timePeriod);
      this.itemToDelete = undefined;
      this.userService.updateOccupancy();
    });
  }

  resetTimePosts() {
    this.timePostService.timePosts =
      [
        {
          timePostId: undefined, date: new Date(), amount: 0, projectId: '',
          projectName: '',
          customerName: '',
          description: '', userId: undefined, 
          attestUserId: null,
          timeCategory: null,
          meeting: false,
          addition: false
        } as Dto.TimePostListDto
      ];
  }

  saveTimePosts(form) {
    FormHelper.touchForm(this.formTextComponents, this.formCheckComponents, this.formSelectComponents, this.formDateComponents);
    if (this.timePostService.timePosts.length > 0) {
      if (form.valid) {
        const tempClone = this.deepClone(this.timePostService.timePosts);
        this.timePostService.saveTimePosts(tempClone).takeUntil(this.unsubscribe)
          .subscribe((exceptionDates: Date[]) => {

            if (this.timePostService.timePosts.length > 1) {
              this.toastr.success('Tidposterna sparades korrekt.', 'OK');
            } else {
              this.toastr.success('Tidposten sparades korrekt.', 'OK');
            }

            // Todo: ExceptionDates here
            if (exceptionDates.length > 0) {
              this.infoModal.openModal(exceptionDates);            
            }

            this.timePostService.loadTimePosts(this.timePeriod);
            this.resetTimePosts();
            this.userService.updateOccupancy();
          });
      }
    } else {
      this.toastr.warning('Du har ingenting att spara, lägg till minst en tidpost för att fortsätta.', 'Varning');
    }
  }

  private deepClone(array: any[]): any[] {
    return JSON.parse(JSON.stringify(array));
  }

  ngOnDestroy() {
    this.timePostHistoryListSub.unsubscribe();
    this.timePeriodSub.unsubscribe();
    this.userProjectListSub.unsubscribe();

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
