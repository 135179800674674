export enum ProjectTypeEnum {
     Standard = 'dcce7c8f-a244-e911-810e-00155d01d20f',
     Education = '82d60c9f-a244-e911-810e-00155d01d20f',
     Flex = '83d60c9f-a244-e911-810e-00155d01d20f',
     Vacation = '43d6f8a7-a244-e911-810e-00155d01d20f',
     VacationUnpayed = '44d6f8a7-a244-e911-810e-00155d01d20f',
     SickLeave = '54a86bba-a244-e911-810e-00155d01d20f',
     ParentalLeave = '55a86bba-a244-e911-810e-00155d01d20f',
     Critical = '400626c2-a244-e911-810e-00155d01d20f',
     Inactive = 'FAE75659-D531-42A7-BA17-A0838AFFBC96',
     Unproductive = 'fae75659-d531-42a7-ba17-a0838affbc96',
     Absence = '71aa2f97-5c76-453f-b645-18032f988ebb',
}