import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeSvExtra from '@angular/common/locales/extra/sv';
import localeSv from '@angular/common/locales/sv';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { svLocale } from 'ngx-bootstrap/locale';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { routing } from './app.routes';
import { NotAuthorizedModule } from './not-authorized/not-authorized.module';
import { CanActivateAuthorized } from './shared/guards/authorized.guard';
import { WinAuthInterceptor } from './shared/interceptors/winauth.interceptor';
import { AccountService } from './shared/services/account.service';
import { SharedModule } from './shared/shared.module';
import { ServerErrorService } from './shared/services/server-error.service';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { LoginModalModule } from './not-authorized/login-modal/login-modal.module';
import { AuthenticationService } from './shared/services/authentication.service';
import * as moment from 'moment';
import { OverviewModule } from './not-authorized/overview/overview.module';
import { BsModalService } from 'ngx-bootstrap';
import { TimeLoggingModule } from './authorize/admin/time-logging/time-logging.module';
import { CanActivateAdmin } from './shared/guards/admin.guard';


registerLocaleData(localeSv, 'sv', localeSvExtra);
defineLocale('sv', svLocale);
moment.locale('sv')

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    HttpClientModule,
    routing,
    TimeLoggingModule,
    NotAuthorizedModule,
    LoginModalModule,
    OverviewModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: false,
    })
  ],
  providers: [
    [{provide: LocationStrategy, useClass: HashLocationStrategy}],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WinAuthInterceptor,
      multi: true
    },
    ServerErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    HttpModule,
    CanActivateAuthorized,
    CanActivateAdmin,
    AccountService,
    AuthenticationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
