import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { environment } from '../../../environments/environment';
import { AccountService } from '../services/account.service';
import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class CanActivateAuthorized {

    constructor(private http: HttpClient, public authenticationService: AuthenticationService, public accountService : AccountService, public router : Router) {   
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.accountService.account.id) {
            return this.authenticationService.checkLogin(this.accountService.account.id).map((res: Dto.AuthorizationInfoDto) => {
                
                this.accountService.setCurrentUser(res);
                this.accountService.account = res.account;
                if (this.accountService.account.lastName == null) {
                    this.accountService.firstLogin = true;
                }
                return res.authenticated;
            }).catch(error => {
                this.router.navigate(['overview']); 
                return Observable.of(error);
            });
        }
    }

    checkActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.accountService.account.id) {
            return this.authenticationService.checkLogin(this.accountService.account.id).map((res: Dto.AuthorizationInfoDto) => {
                this.accountService.setCurrentUser(res);
    
                return res.authenticated;
            }).catch(error => {
                this.router.navigate(['overview']); 
                return Observable.of(error);
            });
        }
    }
}
