import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-validation',
  templateUrl: './form-validation.component.html',
  styleUrls: ['./form-validation.component.scss']
})
export class FormValidationComponent implements OnInit {
  @Input() messages: Array<string>;
  public error: string;

  constructor() { }

  ngOnInit() {
  }
}
