import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { environment } from '../../../environments/environment';

@Injectable()
export class CanActivateAdmin {
    private apiUrl: string;
    private controllerName: string;
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl;
        this.controllerName = 'Account';
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('can activate, guard');
        return this.http.get(this.apiUrl + this.controllerName + '/IsAdmin').map(res => {
            console.log('can activate, ', res);
            
            return res;
        }).catch(error => {
            return Observable.of(error);
        });
    }

    checkActivate() {
        return this.http.get(this.apiUrl + this.controllerName + '/IsAdmin').map(res => {
            console.log('check activate, ', res);
            return res;
        }).catch(error => {
            return Observable.of(error);
        });
    }
}
