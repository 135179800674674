import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { NotAuthorizedComponent } from './not-authorized.component';
import { UserService } from '../shared/services/user.service';
import { OverviewModule } from './overview/overview.module';
import { NotAuthorizedMenuModule } from './components/not-authorized-menu/not-authorized-menu.module';
import { ProjectService } from '../shared/services/project.service';
import { CustomerService } from '../shared/services/customer.service';
import { TimePostService } from '../shared/services/timepost.service';
import { CheckInService } from '../shared/services/checkIn.service';
import { TimerService } from '../shared/services/timer.service';
import { CheckInModule } from './check-in/check-in.module';
import { ToastrService } from 'ngx-toastr';

@NgModule({
  imports: [
    SharedModule,
    OverviewModule,
    NotAuthorizedMenuModule,
    CheckInModule,
  ],
  declarations: [
    NotAuthorizedComponent
  ],
  providers: [
    UserService,  
    ProjectService,
    CustomerService,
    TimePostService,
    CheckInService,
    TimerService,
    ToastrService
  ]
})
export class NotAuthorizedModule { }
