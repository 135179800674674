import {
    AbstractControl,
    AsyncValidatorFn,
    Validator,
    Validators,
    ValidatorFn,
} from '@angular/forms';

import { Observable } from 'rxjs/Observable';

export interface ValidationResult { [validator: string]: string | boolean | any; }

export type AsyncValidatorArray = Array<Validator | AsyncValidatorFn>;

export type ValidatorArray = Array<Validator | ValidatorFn>;

const normalizeValidator =
    (validator: Validator | ValidatorFn): ValidatorFn | AsyncValidatorFn => {
        const func = (validator as Validator).validate.bind(validator);
        if (typeof func === 'function') {
            return (c: AbstractControl) => func(c);
        } else {
            return <ValidatorFn | AsyncValidatorFn>validator;
        }
    };

export const composeValidators =
    (validators: ValidatorArray): AsyncValidatorFn | ValidatorFn => {
        if (validators == null || validators.length === 0) {
            return null;
        }
        return Validators.compose(validators.map(normalizeValidator));
    };

export const validate =
    (validators: ValidatorArray, asyncValidators: AsyncValidatorArray) => {
        return (control: AbstractControl) => {
            const synchronousValid = () => composeValidators(validators)(control);

            if (asyncValidators) {
                const asyncValidator = composeValidators(asyncValidators);

                return asyncValidator(control).map(v => {
                    const secondary = synchronousValid();
                    if (secondary || v) { // compose async and sync validator results
                        return Object.assign({}, secondary, v);
                    }
                });
            }

            if (validators) {
                return Observable.of(synchronousValid());
            }

            return Observable.of(null);
        };
    };

export const message = (validator: ValidationResult, key: string): string => {
    switch (key) {
        case 'required':
            return 'Obligatoriskt';
        case 'email':
            return 'Ogiltig e-post';
        case 'notequal':
            return 'Fälten måste stämma överens';
        case 'requiredaccept':
            return 'Du måste acceptera detta för att fortsätta';
        case 'minnumber':
            return `Värdet måste vara större än ${validator[key].requiredMin}`;
        case 'maxnumber':
            return `Värdet får inte vara större än ${validator[key].requiredMax}`;
        case 'max':
            return 'Value must be a maximum of N characters';
        case 'ngxrequired':
            return 'Fältet får inte vara tomt';
            
        case 'pattern':
            return 'Value does not match required pattern';
        case 'minlength':
            return `Värdet måste vara minst ${validator[key].requiredLength} tecken långt`;
        case 'maxlength':
            return `Värdet får inte vara längre än ${validator[key].requiredLength} tecken långt`;
    }

    switch (typeof validator[key]) {
        case 'string':
            return <string>validator[key];
        default:
            return `Validation failed: ${key}`;
    }
};
