import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { CustomFormsModule } from "../../shared/modules/custom-forms/custom-forms.module";
import { NotAuthorizedMenuModule } from "../components/not-authorized-menu/not-authorized-menu.module";
import { CheckInComponent } from "./check-in.component";
import { NgxSelectModule } from "ngx-select-ex";
import { CheckInHistoryComponent } from "./check-in-history/check-in-history.component";
import { TimePostListModule } from "../../authorize/admin/time-logging/time-post-list/time-post-list.module";
import { FastOrderModalComponent } from "./fast-order-modal/fast-order-modal.component";

@NgModule ( {
    imports: [
        SharedModule,
        CustomFormsModule,
        NotAuthorizedMenuModule,
        NgxSelectModule,
        TimePostListModule,

    ],
    declarations: [
        CheckInComponent,
        CheckInHistoryComponent,
        FastOrderModalComponent
    ],
    providers: [

    ]
})
export class CheckInModule {}