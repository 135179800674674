import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { map } from "rxjs/operators";

import { environment } from "../../../environments/environment";

@Injectable()
export class AuthenticationService {
  private apiUrl: string;
  private controllerName: string;

  constructor(private http: HttpClient, public router: Router) {
    this.apiUrl = environment.apiUrl;
    this.controllerName = "Auth";
  }

  login(username: string, password: string, rememberMe: boolean) {
    return this.http.post(this.apiUrl + this.controllerName + "/Login", {
      username,
      password,
      rememberMe,
    });
  }

  checkLogin(id: string) {
    return this.http.get(
      this.apiUrl + this.controllerName + "/CheckLogin/" + id
    );
  }

  logout(id: string) {
    return this.http.post(this.apiUrl + this.controllerName + "/Logout", {
      userId: id,
    });
  }

  register(
    username: string,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    isAdmin: boolean
  ) {
    return this.http.post(this.apiUrl + this.controllerName + "/Register", {
      id: null,
      username,
      email,
      password,
      firstName,
      lastName,
      isAdmin,
      EvsResursID: 0,
    });
  }

  changePassword(
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
  ) {
    return this.http.post(
      this.apiUrl + this.controllerName + "/ChangePassword",
      {
        currentPassword,
        newPassword,
        confirmPassword,
      }
    );
  }

  adminChangePassword(
    userId: string,
    newPassword: string,
    confirmPassword: string
  ) {
    return this.http.post(
      this.apiUrl + this.controllerName + "/AdminChangePassword",
      {
        userId,
        newPassword,
        confirmPassword,
      }
    );
  }
}
