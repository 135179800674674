import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-fast-order-modal',
  templateUrl: './fast-order-modal.component.html',
  styleUrls: ['./fast-order-modal.component.scss']
})
export class FastOrderModalComponent implements OnInit {
  @Input() fastorder: string;

  createModalRef: BsModalRef;
  editPostForm: FormGroup;

  @ViewChild('createTemplate') template: TemplateRef<void>;
  constructor(
    private modalService: BsModalService,
  
  ){}  

  ngOnInit() {
  }


  openModal() {  
    this.createModalRef = this.modalService.show(this.template, {class: 'modal-lg', keyboard: false});
  }

  closeModal(form) {
    if (form.valid){
      this.editPostForm.reset();
      this.createModalRef.hide();
    }
    else {
      this.editPostForm.reset();
      this.createModalRef.hide();
    }
  }
}
