import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable()
export class CustomerService {

  private baseUrl = environment.apiUrl;
  private controllerName = 'Customer';

  public selectedCustomer = {
    invoiceAddress: {} as Dto.AddressDto,
    mailAddress: {} as Dto.AddressDto,
    visitingAddress: {} as Dto.AddressDto,
  } as Dto.CustomerDto;

  constructor(private http: HttpClient) {
  }

  getCustomer(customerId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/' + customerId);
  }

  createCustomer(customer: Dto.CustomerCreateDto) {
    return this.http.post(this.baseUrl + this.controllerName, customer);
  }

  updateCustomer(customer: Dto.CustomerUpdateDto) {
    return this.http.put(this.baseUrl + this.controllerName, customer);
  }

  removeCustomer(customerId: string) {
    return this.http.delete(this.baseUrl + this.controllerName + '/' + customerId);
  }

  getAllCustomers(model: Dto.PagingDto) {
    return this.http.post(this.baseUrl + this.controllerName + '/AllCustomers', model);
  }


  getAllBasicCustomers() {
    return this.http.get(this.baseUrl + this.controllerName + '/AllBasicCustomers');
  }
}
