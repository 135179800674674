import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { LoginModalComponent } from './not-authorized/login-modal/login-modal.component';

import { CanActivateAuthorized } from './shared/guards/authorized.guard';
import { OverviewComponent } from './not-authorized/overview/overview.component';
import { CheckInComponent } from './not-authorized/check-in/check-in.component';
import { CheckInHistoryComponent } from './not-authorized/check-in/check-in-history/check-in-history.component';
import { CanActivateAdmin } from './shared/guards/admin.guard';


export const routes: Routes = [
    {
        path: '', component: OverviewComponent
    },
    {
        path: 'check-in/:id',
        component: CheckInComponent,
    },
    {
        path: 'check-in-history/:id',
        component: CheckInHistoryComponent
    },
    {
        path: 'login', component: LoginModalComponent,
        data: {
            meta: {
                title: 'Login',
                description: ''
            }
        },
    },
    {
        path: 'admin', canActivate:[CanActivateAdmin], loadChildren: './authorize/admin/admin.module#AdminModule'
    },
    {
        path: '**',
        component: OverviewComponent
    }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {enableTracing: false});
