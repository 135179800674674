import { NgModule } from '@angular/core';

import { TimeDecimalPipe } from './time-decimal.pipe';

@NgModule({
    imports: [
    ],
    declarations: [
        TimeDecimalPipe
    ],
    exports: [
        TimeDecimalPipe
    ]
})
export class timeDecimalPipeModule { }
