import { NgModule } from '@angular/core';
import { NgxSelectModule } from 'ngx-select-ex';

import { ConfirmModalModule } from '../../../../shared/components/confirm-modal/confirm-modal.module';
import { CustomFormsModule } from '../../../../shared/modules/custom-forms/custom-forms.module';
import { SharedModule } from '../../../../shared/shared.module';
import { TimePostCreateComponent } from './time-post-create.component';
import { TimePostExceptionModalModule } from './time-post-exception-modal/time-post-exception-modal.module';

@NgModule({
  imports: [
    SharedModule,
    CustomFormsModule,
    ConfirmModalModule,
    NgxSelectModule,
    TimePostExceptionModalModule
  ],
  declarations: [TimePostCreateComponent],
  exports: [TimePostCreateComponent]
})
export class TimePostCreateModule { }
