import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDecimal'
})
export class TimeDecimalPipe implements PipeTransform {

  transform(value: number): string {
    if (value == null) {
      return '';
    }

    const hours = Math.trunc(value/60);
    const minutes = value % 60;

    const decimalMinutes = minutes / 60;

    const result = hours + decimalMinutes;

    return result.toFixed(2);
  }
}
