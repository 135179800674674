import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { TimePostService } from "../../../../shared/services/timepost.service";
import * as moment from 'moment';
import { Subscription } from "rxjs";
import { ProjectTypeEnum } from "../../../../shared/enums/project-type-enum";

@Component({
  selector: "app-time-post-summary",
  templateUrl: "./time-post-summary.component.html",
  styleUrls: ["./time-post-summary.component.scss"],
})
export class TimePostSummaryComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private timePostHistoryListSub: Subscription = new Subscription(); 

  public loading = true;

  public timePostHistory: Dto.TimePostDto[];
  public projectTypeEnums = ProjectTypeEnum;

  @Input() user: string;

  constructor(
    private timePostService: TimePostService,  
  ) {}

  ngOnInit(){}

  ngOnChanges() {      
    this.loading = true;
    if (this.timePostService.selectedResource) {
      this.timePostHistoryListSub.add(this.timePostService.mapped_timePeriodSubject.subscribe(res => {
        this.timePostService.loadTimePostsUngrouped(res);

        this.timePostService.mapped_projectTimePostHistoryUngrouped.subscribe(res => {
          this.timePostHistory = [...res];
          this.loading = false;
        });
      }));
    }
  }

  TotalAmountByAllTypes() {
    return this.timePostHistory.reduce((sum, x) => sum + x.amount, 0).toFixed(2);
  }

  TotalAmountByProjectType(projectTypeId: string) : string {
    return this.timePostHistory.reduce((sum, x) => sum + (x.projectTypeId === projectTypeId ? x.amount: 0), 0).toFixed(2);
  }

  GetProjectNamesByType(projectTypeId: string) {
    const dict = this.timePostHistory
    .filter(x => x.projectTypeId === projectTypeId)
    .reduce((acc, obj) => {
      let projectName = obj.projectName;

      if (!acc[projectName]) {
        acc[projectName] = {
          projectName,
          totalAmount: 0,
        };
      }

      acc[projectName].totalAmount += obj.amount || 0;

      return acc;
    }, {} as [ {[key: string]: { projectName: string, totalAmount: number} }]);

    return Object.values(dict);
  }

  ngOnDestroy() {
    console.log('destroying');
    
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.timePostHistoryListSub.unsubscribe();
  }
}
