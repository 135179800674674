import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ServerErrorService {

  constructor(private toastr: ToastrService) { }

  showError(response) {
    if (response.error !== null) {
      this.toastr.error(response.error, 'Error');
    } else {
      this.toastr.error('Ett okänt fel har inträffat', 'Error');
    }
  }
}
