import { NgModule } from '@angular/core';
import { NgxChartsModule } from '../../../../../assets/js/ngx-charts-tixor/src/ngx-charts.module';

import { SharedModule } from '../../../../shared/shared.module';
import { TimePostSummaryComponent } from './time-post-summary.component';
import { timeDecimalPipeModule } from '../../../../shared/pipes/time-decimal-pipe.module';

@NgModule({
  imports: [
    SharedModule,
    NgxChartsModule,
    timeDecimalPipeModule
  ],
  declarations: [TimePostSummaryComponent],
  exports: [TimePostSummaryComponent]
})
export class TimePostSummaryModule { }
