import { Directive, NgModule, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appDateValidator]',
  providers: [{ 
      provide: NG_VALIDATORS, 
      useExisting: forwardRef(() =>DateValidatorDirective), 
      multi: true, 
    }
  ]
})
export class DateValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const startDate = control.get('startDate');
    const startTime = control.get('startTime');
    const endDate = control.get('endDate');
    const endTime = control.get('endTime');

    if (startDate && endDate && startDate.value > endDate.value || startTime.value > endTime.value) {
      return { 'dateInvalid': true };
    }
    return null;
  }
}