import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: "app-time-post-exception-modal",
  templateUrl: "./time-post-exception-modal.component.html",
  styleUrls: ["./time-post-exception-modal.component.scss"]
})
export class TimePostExceptionModalComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  public dates = [] as Date[];

  @ViewChild(ModalDirective) infoModal: ModalDirective;

  constructor() {}

  ngOnInit() {}

  openModal(dates: Date[]) {
    this.dates = dates;
    this.infoModal.show();
  }

  closeModal() {
    this.infoModal.hide();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
