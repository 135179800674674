import { Component } from "@angular/core";
import { AccountService } from "./shared/services/account.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "app";

  constructor(private accountService: AccountService) {}

  ngOnInit() {
    // this.accountService.init();
  }
}
