import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { TimeLoggingComponent } from './time-logging.component';
import { TimePostCreateModule } from './time-post-create/time-post-create.module';
import { TimePostListModule } from './time-post-list/time-post-list.module';
import { TimePostSummaryModule } from './time-post-summary/time-post-summary.module';
import { TimePostService } from '../../../shared/services/timepost.service';
import { CustomFormsModule } from '../../../shared/modules/custom-forms/custom-forms.module';

@NgModule({
  imports: [
    SharedModule,
    TimePostCreateModule,
    TimePostListModule,
    TimePostSummaryModule,
	CustomFormsModule
  ],
  declarations: [
    TimeLoggingComponent
  ],
  providers: [TimePostService]
})
export class TimeLoggingModule { }
