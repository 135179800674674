import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import * as signalR from "@aspnet/signalr";
import { TimePostService } from "./timepost.service";
import { mergeMap } from "rxjs-compat/operator/mergeMap";
import { WorkOrderEnum } from "../enums/work-order-type-enum";

@Injectable()
export class CheckInService {
    private hubConnection: signalR.HubConnection;
    private checkInStatus = new BehaviorSubject<Map<string, Dto.TimePostDto>>(new Map());
    private connectionStatus = new BehaviorSubject<boolean>(false);
    
    private retryInterval = 5000; //5 sekunder
    private retryAttempts = 0;
    private maxRetries = 10;

    public connected$ = this.connectionStatus.asObservable();
    public isReconnecting = false;

    constructor(private toastr: ToastrService, private timepostService: TimePostService) {
        this.startConnection();
        this.autoCheckIn();

        this.hubConnection.on('StatusUpdate', (status) => {
            const statusMap = new Map<string, Dto.TimePostDto>();
            
            status.forEach(item => {
                statusMap.set(item.key, item.value);
            });

            console.log('statusmappp', statusMap);
            this.checkInStatus.next(statusMap);
        });
    }

    ngOnInit() {}

    startConnection(): void {
        this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.apiUrl}CheckInHub`)
        .build();
    
        this.hubConnection.start().then(() => {
            console.log('Connected');
            this.connectionStatus.next(true);
            this.retryAttempts = 0;
        }).catch((err) => {
            console.error('Connection failed:', err);
            this.connectionStatus.next(false);
            this.retryConnection();
        });
    
        this.hubConnection.onclose((error: any) => {
            this.toastr.error("Connection to server lost", error);
            this.connectionStatus.next(false);
            console.warn('Connection closed');
            this.retryConnection();
        });
    
        this.hubConnection.on('Disconnect', () => {
            console.log('Disconnected by server');
            this.connectionStatus.next(false);
            this.retryConnection();
        });
    }

    retryConnection(): void {
        if (this.retryAttempts < this.maxRetries) {
            setTimeout(() => {
                this.retryAttempts++;
                console.log(`Retrying connection... Attempt #${this.retryAttempts}`);
                this.startConnection();
            },this.retryInterval);
        } else {
            console.error('Max retry attempts reached. Connection failed.');
        }
    }

    autoCheckIn() {
        //Spara deras nuvarande tidsposter som dom jobbar med.
        //Stämpla sedan in dom på rastordern med detta id : '3219b8c0-3265-ef11-8197-00155d014a04'
        //Efter 15 min så kommer BreakOver att kallas. Då ska dom anställda stämplas ut från rastordern
        //Och in på ordern dom jobbade på innnan rasten.
        this.hubConnection.on('Break', (status: any) => {
            const statusMap = new Map<string, Dto.TimePostDto>(
                status.map((item: { Key: string, Value: Dto.TimePostDto }) => [item.Key, item.Value])
            );

            statusMap.forEach(x => {
                x.endDate = new Date(); 

                //Spara tidsposten innan rast
                this.timepostService.saveTimePost(x)
                .subscribe(() => {
                    this.checkOutOrder(x.userId);

                    //Checkar in användare på rastorder
                    this.timepostService.saveTimePost({
                        ...x,
                        startDate: new Date(),
                        endDate: undefined,
                        timePostId: undefined,
                        workTypeId: WorkOrderEnum.Other,
                        projectId: '3219b8c0-3265-ef11-8197-00155d014a04' //id för rastorder
                    }).subscribe((breakTimepost: Dto.TimePostDto) => {
                        this.checkIn(x.userId, breakTimepost);
                    })
                });
            });    
        });
    
        this.hubConnection.on('CheckOutBreakOrder', (status: any) => {
            const statusMap = new Map<string, Dto.TimePostDto>(
                status.map((item: { Key: string, Value: Dto.TimePostDto }) => [item.Key, item.Value])
            );

            statusMap.forEach(x => {
                x.endDate = new Date(); 
                this.timepostService.saveTimePost(x)
                .subscribe()
            })
            console.log('checkin order', statusMap);
        });

        this.hubConnection.on('BreakOver', (status: any) => {
            const statusMap = new Map<string, Dto.TimePostDto>(
                status.map((item: { Key: string, Value: Dto.TimePostDto }) => [item.Key, item.Value])
            );
            statusMap.forEach(x => {
                x.timePostId = undefined;
                x.startDate = new Date();

                //Stämplar in på tidsposten som dom jobbade med innan rasten
                this.timepostService.saveTimePost(x)
                .subscribe((res: Dto.TimePostDto) => {
                    console.log('Stämplade in ', x, res );
                    
                    this.checkIn(x.userId, res);
                });
            });  
        })
    }

    getStatus() : Observable<Map<string, Dto.TimePostDto>> {
        return this.checkInStatus.asObservable();
    }

    checkIn(userId: string, timepost?: Dto.TimePostDto) {
        this.hubConnection.invoke('CheckIn', userId, timepost).catch(err => console.error(err));
    }

    checkOutOrder(userId: string) {
        this.hubConnection.invoke('CheckOutOrder', userId).catch(err => console.error(err));
    }
    
    processStatus(status: Map<string, Dto.TimePostDto>, userId: string): { isWorking: boolean, isCheckedIn: boolean, timepostId?: string } {
        const workStatus = status.get(userId);
        console.log('workstat', workStatus);
        
        if (workStatus && workStatus.timePostId != "" && workStatus.timePostId != "00000000-0000-0000-0000-000000000000") {
            return { isWorking: true, isCheckedIn: true, timepostId: workStatus.timePostId };
        } else if (status.has(userId)) {
            return { isWorking: false, isCheckedIn: true };
        } else {
            return { isWorking: false, isCheckedIn: false };
        }
    }

    goHome(userId: string){
        this.hubConnection.invoke('GoHome', userId).catch(err => console.error(err));
    }
}