import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

import { ElementBase } from '../../form';


@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormSelectComponent,
    multi: true,
  }],
})
export class FormSelectComponent extends ElementBase<boolean> implements OnInit {
  @Input() public label: string;
  @Input() public name: string;
  @Input() public placeholder: string;
  @Input() public disabled = false;
  @Input() public selectList = [];
  @Input() public selectAttr: string;
  @Input() public showAttr: string;
  @Input() public sm: false;
  @Input() public xs: false;



  @Output() formBlur = new EventEmitter();
  @Output() formFocus = new EventEmitter();
  @Output() change = new EventEmitter();

  @ViewChild(NgModel) model: NgModel;
  @ViewChild('select') select;

  public identifier = `form-select-${identifier++}`;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  public markAsTouched() {
    this.model.control.markAsTouched();
  }
  public markAsDirty() {
    this.model.control.markAsDirty();
  }
  public markAsPristine() {
    this.model.control.markAsPristine();
  }

  blur() {
    this.formBlur.emit(event);
  }

  focus() {
    this.formFocus.emit(event);
  }

  formChange() {
    this.change.emit(event);
  }

  ngOnInit() {

  }
}


let identifier = 0;
