import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

import { environment } from "../../../environments/environment";

@Injectable()
export class AccountService {
  public currentUser: string;
  public firstLogin = false;
  public loggedIn = false;
  private STORAGE_ID: string = "id";

  public isAdmin = false;
  public isDev = false;
  public isProjectmanager = false;
  public isAdminOrProjectmanager = false;
  public account = {
    address: {
      countryId: null,
    },
  } as Dto.UserPublicDto;

  private baseUrl = environment.apiUrl;
  private controllerName = "Account";

  
  public accountIsSet: Subject<Dto.UserPublicDto> = new Subject<Dto.UserPublicDto>();

  constructor(private http: HttpClient) {}

  init() {
    const id = localStorage.getItem(this.STORAGE_ID);
    this.account.id = id;
  }

  logout() {
    this.currentUser = "";
    this.account = {} as Dto.UserPublicDto;
    localStorage.removeItem(this.STORAGE_ID);
    this.isAdmin = false;
    this.isDev = false;
    this.isProjectmanager = false;
    this.isAdminOrProjectmanager = false;
    this.loggedIn = false;

  }

  setCurrentUser(data: Dto.AuthorizationInfoDto) {
    this.currentUser = data.userName;
    this.account = data.account;
    if (data.account && data.account.id) {
      localStorage.setItem(this.STORAGE_ID, data.account.id);
    }
  }

  getAccount() {
    return this.http
      .get(this.baseUrl + this.controllerName + "/GetAccount")
      .subscribe((res: Dto.UserPublicDto) => {
        this.account = res;
        this.accountIsSet.next(res);
      });
  }

  createUser(user: Dto.UserCreateDto) {
    return this.http.post(
      this.baseUrl + this.controllerName + "/CreateUser",
      user
    );
  }

  updateUser(user: Dto.UserDto) {
    user.userName =
      user.firstName.toLowerCase().substring(0, 1) +
      user.lastName.toLowerCase();
    return this.http.put(
      this.baseUrl + this.controllerName + "/UpdateUser",
      user
    );
  }

  updateCurrentUser(user: Dto.UserPublicDto) {
    return this.http.put(
      this.baseUrl + this.controllerName + "/UpdateCurrentUser",
      user
    );
  }

  saveAccountKeyStats() {
    return this.http.get(
      this.baseUrl + this.controllerName + "/SaveAccountKeyStats"
    );
  }
}
