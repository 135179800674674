import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: 'ngx-required',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NgxSelectValidatorDirective),
            multi: true
        },
    ]
})
export class NgxSelectValidatorDirective implements Validator {

    constructor() { }

    validate(c: AbstractControl): ValidationErrors | any {

        if (c.value == undefined) {
            return { ngxrequired: true };
        }

        return null;
    }
}