import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ProjectService } from '../../../../shared/services/project.service';
import { TimePostService } from '../../../../shared/services/timepost.service';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ProjectTypeEnum } from '../../../../shared/enums/project-type-enum';
import { UserService } from '../../../../shared/services/user.service';
import { AccountService } from '../../../../shared/services/account.service';
import { EditTimePostModalComponent } from '../../../../shared/components/edit-timepost/edit-timepost-modal.component';
import { takeUntil } from 'rxjs-compat/operator/takeUntil';

@Component({
  selector: 'app-time-post-list',
  templateUrl: './time-post-list.component.html',
  styleUrls: ['./time-post-list.component.scss']
})
export class TimePostListComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private subscription: Subscription = new Subscription();

  public timePostHistory: Dto.TimePostGroupDto[];
  public timePosts: Dto.TimePostListDto[];
  public workTypes = {} as Dto.WorkTypeDto[];
  private timePeriod: Date[];
  resourcesList: Dto.ResourcesListDto[] = [];


  public dateSpan = {
    // todo: base on initial timeperiod
    startDate: new Date(moment().subtract(30, 'days').format('YYYY-MM-DD')),
    endDate: new Date(moment().add(1, 'days').format('YYYY-MM-DD'))
  };

  @Input() editable = true;
  @Input() projectId: string;
  @Input() showUser = false;
  @Input() adminList = false;
  @Input() attestant = false;
  @Input() exportExcel = false;
  @Input() excelName: string;
  @Input() allUsers = true;
  @Input() public resourcesInRoleList: Dto.ResourcesListDto[];

  private timePostHistoryListSub: Subscription;
  private timePeriodSub: Subscription;
  private projectTimePeriodSub: Subscription;
  private projectTimePostHistoryListSub: Subscription;
  sliceLength: boolean;

  @ViewChild('EditTimePost') EditTimePost: EditTimePostModalComponent;

  constructor(public timePostService: TimePostService, private toastr: ToastrService, public projectService: ProjectService, private userService: UserService, public accountService: AccountService) { 
    this.sliceLength = false;
  }
    

  ngOnInit() {
    console.log('resource', this.timePostService.selectedResource);
    
    if (!this.timePostService.selectedResource) {
      this.timePostService.selectedResource = localStorage.getItem("id");
    }

    this.initializePosts();
    this.getWorkTypes();
    this.getResourcesList();
    // this.getResourcesInRoleList();

    this.subscription.add(this.timePostService.timePostChanged$.subscribe(() => this.initializePosts()));
  }

  initializePosts() {
    if (!this.adminList) {
      if (this.projectId === undefined) {
        this.timePostHistoryListSub = this.timePostService.mapped_timePostHistoryList.subscribe(res => {
          this.timePostHistory = [...res];
          console.log(res);
          
        });
      } else {
        this.projectTimePostHistoryListSub = this.timePostService.mapped_projectTimePostHistoryList.subscribe(res => {
          this.timePostHistory = [...res];
          console.log('Timepost history', this.timePostHistory);
          
        });
      }
      
      if (this.attestant) {
        this.timePeriodSub = this.timePostService.mapped_projectTimePeriodSubject.subscribe(res => {
          this.timePeriod = res;
          this.timePostService.loadTimePostsForAttestant(this.timePeriod,this.accountService.account.id);
        })
      }
      else if (this.projectId === undefined ) {
        this.timePeriodSub = this.timePostService.mapped_timePeriodSubject.subscribe(res => {
          this.timePeriod = res;
          this.timePostService.loadTimePosts(this.timePeriod);
        });
      } else {
        this.projectTimePeriodSub = this.timePostService.mapped_projectTimePeriodSubject.subscribe(res => {
          this.timePeriod = res;
          this.timePostService.loadTimePostsForProject(this.projectId, this.timePeriod, this.projectService.publicSelectedProjectTagId);
        });
      }
      
    }
  }

  getResourcesInRoleList() {
    this.userService
    .getUsersByRole('Projectmanager')
    .subscribe((result: Dto.ResourcesListDto[]) => {
      this.resourcesInRoleList = result;
    });
  }

  getWorkTypes() {
    this.timePostService.getWorkTypes()
    .subscribe((res: Dto.WorkTypeDto[]) => {
      this.workTypes = res;
    });
  }

  getWorkById(workTypeId: string) : Dto.WorkTypeDto {
    if (workTypeId && this.workTypes.length > 0){
      return this.workTypes.find(o => o.workTypeId == workTypeId);
    }
  }

  getAttestantName(attestUserId) {
    if(this.resourcesInRoleList)  {
      let attestantName = "";
      this.resourcesInRoleList.forEach(user => {
        if(user.id == attestUserId){
          attestantName = user.name;
        }
      });
      return attestantName;   
    }
    return null;
  }

  updateDateSpan() {
    const startDate = this.dateSpan.startDate;
    const endDate = this.dateSpan.endDate;
    
    if (this.projectId === undefined) {
      this.timePostService.timePeriodSubject.next([startDate, endDate]);
    } else {   
      this.timePostService.projectTimePeriodSubject.next([startDate, endDate]);
    }
  }

  editTimePost(post) {
    this.EditTimePost.openModal(false, post);
  }

  lockTimePost(post) {
    if (this.editable && !post.locked) { 
      post.locked = true;
      this.timePostService.lockTimePost(post.timePostId, this.accountService.account.id)
      .takeUntil(this.unsubscribe).
      subscribe(res => {res});
    }

  }

  sendNotApprovedMail(post) {
    this.timePostService.sendAttestEmail(post.timePostId).takeUntil(this.unsubscribe).subscribe(res => {
      this.toastr.success("Ett mail har skickats");
    });
  }

  getDayName(date) {
    return new Date(date).toLocaleDateString('sv-SE', { weekday: 'long' });
  }

  exportToExcel(projectId?: string, name?: string) {
    console.log('selected resource', this.userService.selectedUser.id);
    
    const dateSpan = {
      startDate: this.timePeriod[0],
      endDate: this.timePeriod[1],
      projectId: projectId,
      projectTagId: this.projectService.publicSelectedProjectTagId,
      userId: this.userService.selectedUser.id,
    } as Dto.ProjectTimeSpanDto;
    this.timePostService.exportToExcel(dateSpan, name).subscribe(res => {
      this.toastr.success('Export av tidredovisning för "' + (name) + '" genomfördes korrekt.', 'OK');
    });
  }

  getWeekTimeCount(week) {
    let totalCount = 0;
    week.weekDays.forEach(day => {
      day.timePosts.forEach(post => {
        totalCount = totalCount + post.amount;
      });
    });
    return parseFloat(totalCount.toFixed(2));
  }

  getVacationAndParentalLeaveDay(week) {
    let totalCount = 0;
    week.weekDays.forEach(day =>{
      day.timePosts.forEach(post =>{
        if(post.projectTypeId === ProjectTypeEnum.ParentalLeave ||
          post.projectTypeId === ProjectTypeEnum.Vacation ||
          post.projectTypeId === ProjectTypeEnum.SickLeave ||
          post.projectTypeId === ProjectTypeEnum.VacationUnpayed){
          totalCount = totalCount + post.amount;
        }
      });
    });
    return totalCount;
  }

  getDayTimeCount(day) {
    let totalCount = 0;
    day.timePosts.forEach(post => {
      totalCount = totalCount + post.amount;
    });
    return parseFloat(totalCount.toFixed(2));
  }

  resetTimeSpan() {
    this.dateSpan = {
      startDate: new Date(moment().subtract(30, 'days').format('YYYY-MM-DD')),
      endDate: new Date(moment().add(1, 'days').format('YYYY-MM-DD'))
    };
    this.updateDateSpan();
  }
  getResourcesList() {
    this.userService
      .getAllResources()
      .subscribe((result: Dto.ResourcesListDto[]) => {
        console.log(this.resourcesList);
        
        this.resourcesList = result;
      });
  }
  reloadTimePosts() {
    if (this.timePostService.selectedResource === null) {
      //Hämtar inloggads id
      this.timePostService.selectedResource = localStorage.getItem("id");
    }
    this.userService.getUser(this.timePostService.selectedResource)
    .subscribe((res: Dto.UserDto) => {
      console.log("Res", res);
      
      this.userService.selectedUser = res;
      const [startTime, endTime] = this.timePostService.timePeriodSubject.value;
      this.timePostService.loadTimePosts([startTime, endTime]);
    }) 
  }
  ngOnDestroy() {

    this.timePostService.timePostHistoryList.next([]);
    this.timePostService.projectTimePostHistoryList.next([]);

    if (this.timePostHistoryListSub !== undefined) {
      this.timePostHistoryListSub.unsubscribe();
    }

    if (this.projectTimePostHistoryListSub !== undefined) {
      this.projectTimePostHistoryListSub.unsubscribe();
    }

    if (this.timePeriodSub !== undefined) {
      this.timePeriodSub.unsubscribe();
    }

    if (this.projectTimePeriodSub !== undefined) {
      this.projectTimePeriodSub.unsubscribe();
    }

    this.subscription.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
