import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from '../../../environments/environment';
import { TimeHelper } from '../helpers/time-helpers';
import { CorporationEnum } from '../enums/corporation-enum';
import { CurrencyEnum } from '../enums/currency-enum';
import { CustomerEnum } from '../enums/customer-enum';
import { ProjectTypeEnum } from '../enums/project-type-enum';

@Injectable()
export class ProjectService {

  private baseUrl = environment.apiUrl;
  private controllerName = 'Project';

  public userProjectList: BehaviorSubject<Dto.ProjectListDto[]> = new BehaviorSubject<Dto.ProjectListDto[]>([]);
  public userProjects: Dto.ProjectListDto[];

  public customerProjects: Dto.ProjectDto[] = [];
  public specialOrderIds: string[] = [];

  public publicSelectedProject = {} as Dto.ProjectDto;
  public publicSelectedProjectTagId = null as string;
  public publicSelectedAttestTagId = null as string;

  public selectedOption: any = null;
  public search = {
    searchText: '',
    active: true,
    lastRegisterdDate: -10000,
    projectTypeId: "dcce7c8f-a244-e911-810e-00155d01d20f",
    sortAscending: true,
  } as Dto.SortLastRegisterdDto

  public selectedProject = {
    customerId: CustomerEnum.Aros,
    invoiceHold: true,
    wageCurve: true,
    registrationDefault: true,
    orderDate: new Date(),
    colorTheme: '#12486d',
    currencyTypeId: CurrencyEnum.SEK,
    corporationId: CorporationEnum.Aros,
    active: true,
    projectTypeId: ProjectTypeEnum.Standard,
    contactId: '5B9ED5F1-0492-EB11-8147-000D3AB04A0E', //Martin hos Aros
    invoiceCreate: true,
    projectTags: [],
    attestTags: [],
    hiddenProject: false,
    hourPrice: 300,
  } as Dto.ProjectDto;

  public projectTypeList: Dto.ProjectTypeDto[] = [];
  public corporationList: Dto.CorporationDto[] = [];

  public mapped_userProjectList = this.userProjectList.asObservable();

  constructor(private http: HttpClient) {
    this.userProjectList.subscribe(res => {
      this.userProjects = res;
    });

    this.getAllProjectTypes().subscribe((res: Dto.ProjectTypeDto[]) => {
      this.projectTypeList = res;
    })
  }

  initProject() : Dto.ProjectDto {
    return {
      customerId: CustomerEnum.Aros,
      invoiceHold: true,
      wageCurve: true,
      registrationDefault: true,
      orderDate: new Date(),
      colorTheme: '#12486d',
      currencyTypeId: CurrencyEnum.SEK,
      corporationId: CorporationEnum.Aros,
      active: true,
      projectTypeId: ProjectTypeEnum.Standard,
      contactId: '5B9ED5F1-0492-EB11-8147-000D3AB04A0E', //Martin hos Aros
      userId: undefined,
      invoiceCreate: true,
      projectTags: [],
      attestTags: [],
      hiddenProject: false,
      hourPrice: 300,
    } as Dto.ProjectDto;
  }

  getProject(projectId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/' + projectId);
  }

  
  getProjectByIdSimple(projectId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/GetProjectByIdSimple/' + projectId);
  }


  getProjectByOrderNumber(orderNumber: number) {
    return this.http.get(this.baseUrl + this.controllerName + `/GetProjectByOrderNumber/${orderNumber}`);
  }

  getProjectPublic(projectId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/Public/' + projectId);
  }

  addProjectToCurrentUser(projectId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/AddProjectToCurrentUser/' + projectId);
  }
  removeProjectFromCurrentUser(projectId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/RemoveProjectFromCurrentUser/' + projectId);
  }

  addProjectToUser(projectId: string, userId: string) {
    return this.http.post(this.baseUrl + this.controllerName + '/AddProjectToUser/', { ProjectId: projectId, UserId: userId });
  }

  removeProjectFromUser(projectId: string, userId: string) {
    return this.http.post(this.baseUrl + this.controllerName + '/RemoveProjectFromUser/', { ProjectId: projectId, UserId: userId });
  }

  setProjectColor(projectId: string, color: string) {
    return this.http.post(this.baseUrl + this.controllerName + '/SetProjectColor/', { ProjectId: projectId, Color: color })
  }

  createProject(project: Dto.ProjectCreateDto) {
    project.orderDate = TimeHelper.ToUTCTime(project.orderDate);
    return this.http.post(this.baseUrl + this.controllerName, project);
  }

  updateProject(project: Dto.ProjectUpdateDto) {
    project.orderDate = TimeHelper.ToUTCTime(project.orderDate);
    return this.http.put(this.baseUrl + this.controllerName, project);
  }

  removeProject(projectId: string) {
    return this.http.delete(this.baseUrl + this.controllerName + '/' + projectId);
  }

  getAllProjects(paging?: Dto.PagingDto, model?: Dto.SortLastRegisterdDto) {
    const params = new HttpParams() 
      .set('page', paging.page.toString())
      .set('size', paging.size.toString());

    return this.http.post(this.baseUrl + this.controllerName + '/AllProjects', model, {params, headers: new HttpHeaders({'Content-Type': 'application/json'})});
  }

  getAllCustomerProjectSimple(customerId: string){
    return this.http.get(this.baseUrl + this.controllerName + '/AllCustomerProjectSimple/' + customerId);
  }

  getAllProjectByProjectType(projectTypeId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/GetAllProjectsByProjectType/' + projectTypeId);
  }

  getNotConnectedProjectsForCurrentUser() {
    return this.http.get(this.baseUrl + this.controllerName + '/GetNotConnectedProjectsForCurrentUser');
  }

  getNotConnectedProjectsForUser(userId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/GetNotConnectedProjectsForUser/' + userId);
  }

  getProjectsForCurrentUser() {
    return this.http.get(this.baseUrl + this.controllerName + '/GetProjectsForCurrentUser');
  }

  getProjectsForUser(userId: string) {
    return this.http.get(this.baseUrl + this.controllerName + '/GetProjectsForUser/' + userId);
  }

  getProjectsForCustomer(model: Dto.PagingIdDto) {
    return this.http.post(this.baseUrl + this.controllerName + '/GetProjectsForCustomer/', model);
  }

  getProjectNameById(id) {
    if (id !== undefined) {
      const project = this.userProjects.find(x => x.projectId === id);
      if (project !== undefined) {
        return project.orderNumber;
      }
    }
    return '';
  }

  getAllProjectTypes() {
    return this.http.get(this.baseUrl + this.controllerName + '/AllProjectTypes');
  }

  getAllCorporations() {
    return this.http.get(this.baseUrl + this.controllerName + '/AllCorporations');
  }

  saveProjectNote(project: Dto.ProjectListDto) {
    return this.http.post(this.baseUrl + this.controllerName + '/SaveProjectNote', project);
  }
}
