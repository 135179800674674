import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { CustomFormsModule } from '../../../../../shared/modules/custom-forms/custom-forms.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { TimePostExceptionModalComponent } from './time-post-exception-modal.component';



@NgModule({
  imports: [
    SharedModule,
    ModalModule.forRoot(),
    CustomFormsModule
  ],
  declarations: [
    TimePostExceptionModalComponent
  ],
  exports: [
    TimePostExceptionModalComponent
  ]
})
export class TimePostExceptionModalModule { }
