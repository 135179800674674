import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { CustomFormsModule } from "../../shared/modules/custom-forms/custom-forms.module";
import { OverviewComponent } from "./overview.component";
import { HeaderModule } from "../../authorize/components/header/header.module";
import { DatePipe } from "@angular/common";
import { NotAuthorizedModule } from "../not-authorized.module";
import { NotAuthorizedMenuModule } from "../components/not-authorized-menu/not-authorized-menu.module";
import { NgxSelectModule } from "ngx-select-ex";

@NgModule({
    imports: [
      SharedModule,
      CustomFormsModule,
      NotAuthorizedMenuModule,
      NgxSelectModule
    ],
    declarations: [
      OverviewComponent,
    ],
    providers: [
      DatePipe,
    ]
  })
  export class OverviewModule { }
  