import { NgModule } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { NotAuthorizedMenuComponent } from "./not-authorized-menu.component";

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        NotAuthorizedMenuComponent
    ],
    exports: [
        NotAuthorizedMenuComponent
    ]
})
export class NotAuthorizedMenuModule { }
