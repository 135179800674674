import { NgModule } from '@angular/core';

import { EditTimePostModalComponent } from './edit-timepost-modal.component';
import { ModalModule, TimepickerConfig, TimepickerModule } from 'ngx-bootstrap';
import { CustomFormsModule } from '../../modules/custom-forms/custom-forms.module';
import { SharedModule } from '../../shared.module';
import { TimepickerActions } from 'ngx-bootstrap/timepicker';
import { DateValidatorDirective } from '../../directives/validator-date/validator-date.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmModalModule } from '../confirm-modal/confirm-modal.module';
import { NgxSelectModule } from 'ngx-select-ex';

@NgModule({
    imports: [
        SharedModule,
        ModalModule,
        CustomFormsModule,
        TimepickerModule,
        ReactiveFormsModule,
        ConfirmModalModule,
        NgxSelectModule

    ],
    declarations: [
        EditTimePostModalComponent,
        DateValidatorDirective
    ],
    exports: [
        EditTimePostModalComponent
    ],
    providers: [
        TimepickerActions,
    ]
})
export class EditTimePostModalModule { }
