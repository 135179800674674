import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { Router } from "@angular/router";
import { FormTextComponent } from "../../shared/components/form-text/form-text.component";
import { FormCheckComponent } from "../../shared/components/form-check/form-check.component";
import { Observable } from "rxjs/Observable";

import { AccountService } from "../../shared/services/account.service";
import { AuthenticationService } from "../../shared/services/authentication.service";

@Component({
  selector: "app-login-modal",
  templateUrl: "./login-modal.component.html",
  styleUrls: ["./login-modal.component.scss"],
})
export class LoginModalComponent implements OnInit {
  @ViewChildren(FormTextComponent)
  formTextComponents: QueryList<FormTextComponent>;
  @ViewChildren(FormCheckComponent)
  formCheckComponents: QueryList<FormCheckComponent>;

  public loginInfo = {
    username: "",
    password: "",
    rememberMe: true,
  } as Dto.AuthorizationLoginDto;

  public loginLoading = false;

  constructor(
    public accountService: AccountService,
    public authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginLoading = true;
    const id = localStorage.getItem('id');
    console.log('local storage', id);
    
    if (id) {
      this.router.navigate(["/admin/"]);
      this.loginLoading = false;
    } else {
      this.loginLoading = false;
    }
  }

  login() {
    this.loginLoading = true;
    this.authenticationService
      .login(
        this.loginInfo.username,
        this.loginInfo.password,
        this.loginInfo.rememberMe
      )
      .catch((error) => {
        this.loginLoading = false;
        return Observable.of(error);
      })
      .subscribe((res: Dto.AuthorizationInfoDto) => {
        this.accountService.loggedIn = res.authenticated;
        // this.accountService.account = res.account;
		    this.accountService.setCurrentUser(res);
        if (this.accountService.account && this.accountService.account.lastName == null) {
          this.accountService.firstLogin = true;
        }

        this.loginLoading = false;

        this.router.navigate(["/admin/"]);
      }, err => {
        this.router.navigate(["login"]);
		    console.log(err);
	    }
    );
  }

  back() {
    this.router.navigate(['overview'])
  }
}
